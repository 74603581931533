<script setup lang="ts">
import { ref, computed } from "vue";
import type { Ref } from "vue";
import { signIn, rememberDevice } from "aws-amplify/auth";
import { useRoute, useRouter } from "vue-router";
import { useBookingData } from "@/stores/booking-storage";
import { isMobile } from "@/utilities/IsMobileHelper";

const route = useRoute();
const usernameInput: Ref<string> = ref("");
const passwordInput: Ref<string> = ref("");
const errorState: Ref<boolean> = ref(false);
const rememberMe: Ref<boolean> = ref(false);
const errorMessage: Ref<string> = ref("");
const isLoading: Ref<boolean> = ref(false);
const router = useRouter();
const bookingStore = useBookingData();
const mobileView = computed(() => isMobile());
const isStandaloneLoginPage = computed(() => route.name === "login");

const emit = defineEmits(["isLoading", "isAuthenticated"]);
interface CognitoError {
    message: string
    __type: string
}

async function rememberUserDevice() {
    try {
        await rememberDevice();
    } catch (error) {
        errorState.value = true;
        const rawError = error as CognitoError;
        errorMessage.value = rawError.message;
    }
}

const isRedirectPathValid = (path: string): boolean => {
    let status: boolean = false;
    try {
        const url = new URL(path, window.location.origin);
        status = url.origin === window.location.origin;
    } catch (e) {
        status = false;
    }
    return status;
};

async function handleSignIn(username: string, password: string) {
    try {
        isLoading.value = true;
        const result = await signIn({ username, password });
        isLoading.value = false;
        if (result.isSignedIn) {
            if (rememberMe.value) {
                await rememberUserDevice();
            }
            errorState.value = false;
            const bookingId = bookingStore.checkExistingBooking();
            const { redirectUri } = route.query;
            if (bookingId) {
                emit("isAuthenticated", true);
                // if we're currently on the login page, redirect to the booking page
                if (isStandaloneLoginPage.value) {
                    await router.push("/your-basket");
                }
            } else if (redirectUri && isRedirectPathValid(redirectUri as string)) {
                await router.push(redirectUri as string);
            } else {
                await router.push("/");
            }
        }
    } catch (error) {
        isLoading.value = false;
        errorState.value = true;
        const rawError = error as CognitoError;
        errorMessage.value = rawError.message;
    }
}
async function doAuth() {
    await handleSignIn(usernameInput.value, passwordInput.value);
}

function toggleRememberDevice() {
    rememberMe.value = !rememberMe.value;
}
</script>

<template>
    <v-container>
        <v-overlay v-model="mobileView" persistent class="align-center justify-center">
            <div class="mobile-warning">
                <img src="@/assets/mtt-logo.svg" width="185" height="28" alt="MyTrainTicket">
                <div>Coming soon to mobile, please use a desktop to make bookings in the interim.</div>
            </div>
        </v-overlay>
        <v-row justify="center">
            <v-col :cols="isStandaloneLoginPage ? '4' : '12'" sm="12" :xl="isStandaloneLoginPage ? '4' : '12'" :md="isStandaloneLoginPage ? '6' : '12'">
                <!-- if we have a redirectUri, tell the user they need to login to continue -->
                <v-alert v-if="route.query.redirectUri" type="info" class="mb-5">
                    You need to login to continue on this booking site
                </v-alert>
                <v-card class="guest-checkout">
                    <v-card-title>Login</v-card-title>
                    <v-card-text class="checkout-section">
                        <v-form ref="form" :class="{'error-state': errorState }">
                            <main class="about app">
                                <form @submit.prevent="doAuth">
                                    <div>
                                        <v-text-field
                                            label="Username"
                                            type="email"
                                            id="username"
                                            name="username"
                                            v-model="usernameInput"
                                            required></v-text-field>
                                    </div>
                                    <div>
                                        <v-text-field
                                            label="Password"
                                            type="password"
                                            id="pass"
                                            name="password"
                                            v-model="passwordInput"
                                            required></v-text-field>
                                    </div>
                                    <v-checkbox label="Remember me" @click="toggleRememberDevice()"></v-checkbox>
                                    <div v-if="errorState" class="error-message pb-5"> {{ errorMessage }}</div>
                                    <v-btn class="primary-button" :disabled="isLoading" type="submit" value="Sign in">Login <v-progress-circular indeterminate size="15" class="ml-1" v-if="isLoading"></v-progress-circular></v-btn>
                                </form>
                            </main>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped lang="scss">
.error-message {
  color: var(--vt-c-error-red);
}

.error-state {
  :deep(label.v-label.v-field-label.v-field-label--floating) {
    color: var(--vt-c-error-red);
  }

  :deep(input#pass), :deep(input#username) {
    background-color: var(--vt-c-white);
    border-bottom: 1px solid var(--vt-c-error-red);
    color: var(--vt-c-error-red);
  }
}

:deep(.v-field__field) {
  background-color: var(--vt-c-white);
}

.v-btn.primary-button {
  box-shadow: none;
}
@media only screen and (max-width: 760px) {
    .mobile-warning {
        font-size: 17px;
        background-color: var(--vt-c-orange);
        color: var(--vt-c-white);
        padding: 25px;
        line-height: 2.5;
        border-radius: 10px;
        text-align: center;
        img {
            scale: 2.25;
        }
    }
    .v-card.guest-checkout {
        width: max-content;
    }
}

</style>
