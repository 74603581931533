import { GQLCorporateService } from "@/services/GQLCorporateService";
import type { CompanyDetails } from "@/models/graphql/CompanyDetailsTypes";
import type { CorporateUserDetails } from "@/models/graphql/CorporateUserTypes";
import type { ManagementInformationQuestionsResponse } from "@/models/graphql/ManagementInformationQuestionsTypes";
import ApiClient, { type IApiClient } from "./ApiClient";

export interface IGQLCorporateClient {
    getCompanyDetails(): Promise<CompanyDetails>
    getCorporateUserDetails(): Promise<CorporateUserDetails>
    getManagementInformationQuestions(): Promise<ManagementInformationQuestionsResponse>
}

const gqlCompanyDetailsEndpoint: string = "/gql/company-details";
const gqlCorporateUserDetailsEndpoint: string = "/gql/corporate-user-details";
const gqlManagementInformationQuestionsEndpoint: string = "/gql/mi-questions";

export class GQLCorporateClient implements IGQLCorporateClient {
    gqlClient: IApiClient;

    constructor(gqlClient: IApiClient) {
        this.gqlClient = gqlClient;
    }

    async getCompanyDetails(): Promise<CompanyDetails> {
        return this.gqlClient.get(gqlCompanyDetailsEndpoint);
    }

    async getCorporateUserDetails(): Promise<CorporateUserDetails> {
        return this.gqlClient.get(gqlCorporateUserDetailsEndpoint);
    }

    async getManagementInformationQuestions(): Promise<ManagementInformationQuestionsResponse> {
        return this.gqlClient.get(gqlManagementInformationQuestionsEndpoint);
    }
}

const gqlCorporateClient = new GQLCorporateClient(new ApiClient());
export const gqlCompanyService = new GQLCorporateService(gqlCorporateClient);
