<script setup lang="ts">
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import type { Ref } from "vue";
import GuestCheckoutDetailsForm from "@/components/4-Checkout/GuestCheckoutDetailsForm.vue";
import SecureLogin from "@/components/4-Checkout/SecureLogin.vue";
import type { GuestUserDataModel } from "@/models/checkout/UserDataModel";
import { fulfilmentService } from "@/services/FulfilmentService";
import { fetchAuthSession } from "aws-amplify/auth";
import { handleError } from "@/services/AlertService";
import { DeliveryMethods } from "@/models/basket/DeliveryMethods";
import type { IGuestCheckoutRequest, IAuthenticatedCheckoutRequest, SubmittedManagementQuestionAnswer } from "@/models/checkout/GuestCheckoutRequest";
import type { IBookingData } from "@/models/checkout/FulfilmentRequestModel";
import StripeWidget from "@/components/4-Checkout/StripeWidget.vue";
import UserPayment from "@/components/4-Checkout/UserPayment.vue";
import { useBookingData } from "@/stores/booking-storage";
import { Hub } from "aws-amplify/utils";
import CheckoutLhs from "@/components/4-Checkout/CheckoutLhs.vue";
import type { SubmitPaymentEventData } from "@/models/checkout/SubmitPaymentEventData";
import PaymentConfirmation from "./PaymentConfirmation.vue";

const togglePayment: Ref<boolean> = ref(false);
const paymentConfirmed: Ref<boolean> = ref(false);
const isLoading: Ref<boolean> = ref(false);
const guestUserFirstName: Ref<string> = ref("");
const guestUserSurname: Ref<string> = ref("");
const userEmail: Ref<string> = ref("");
const route = useRoute();
const loadingMessage: Ref<string> = ref("Please wait, bookings can take up to 20 seconds");
const bookingData: Ref<IBookingData | null> = ref(null);
const isAuthUser: Ref<boolean> = ref(false);

const bookingDataStore = useBookingData();
async function resyncAuthUser() {
    const session = await fetchAuthSession();
    if (session.tokens !== undefined) { // if we're logged in
        isAuthUser.value = true;
        togglePayment.value = true;
        userEmail.value = session.tokens.signInDetails!.loginId!;
    } else {
        isAuthUser.value = false;
        togglePayment.value = false;
    }
}

Hub.listen("auth", () => {
    resyncAuthUser();
});

function toggleLoading(loadingStatus: boolean) {
    isLoading.value = loadingStatus;
}

function setGuestUserData(userData: GuestUserDataModel) {
    guestUserFirstName.value = userData.firstName;
    guestUserSurname.value = userData.surname;
    userEmail.value = userData.userEmail;
    togglePayment.value = true;
}

onMounted(async () => {
    await resyncAuthUser();
});

const bookingId = route.query.bookingId as string;

async function submitPayment(eventData: SubmitPaymentEventData) {
    let fulfilmentMethod = route.query.deliveryMethod as string;
    fulfilmentMethod = fulfilmentMethod === DeliveryMethods.ETicket ? DeliveryMethods.ETicket : "tod";
    try {
        isLoading.value = true;
        loadingMessage.value = "Please wait, bookings can take up to 20 seconds";
        if (isAuthUser.value) {
            const request = {
                fulfilmentMethod,
            } as IAuthenticatedCheckoutRequest;
            if (eventData.miQuestionAnswers) {
                request.managementQuestionAnswers = Object.keys(eventData.miQuestionAnswers).map((key) => ({
                    key,
                    value: eventData.miQuestionAnswers![key]!,
                }) as SubmittedManagementQuestionAnswer);
            }
            bookingData.value = eventData.onAccount ? await fulfilmentService.authenticatedOnAccountPayment(bookingId, request)
                : await fulfilmentService.authenticatedPayment(bookingId, request);
        } else {
            const request = {
                bookingId,
                email: userEmail.value,
                firstName: guestUserFirstName.value,
                lastName: guestUserSurname.value,
                fulfilmentMethod,
            } as IGuestCheckoutRequest;
            bookingData.value = await fulfilmentService.completeGuestBooking(bookingId, request);
        }
        paymentConfirmed.value = true;
        togglePayment.value = false;
        isLoading.value = false;
        loadingMessage.value = "";
        bookingDataStore.clearBookingData();
    } catch (error) {
        handleError(error);
        isLoading.value = false;
        loadingMessage.value = "";
    }
}
</script>

<template>
    <div>
        <v-overlay :model-value="isLoading" :content-class="'justify-center align-center d-flex flex-column w-100 h-100'">
            <v-progress-circular
                indeterminate
                :size="70"
                :width="7"
                color="primary">
            </v-progress-circular>
            <h2> {{ loadingMessage }}</h2>
        </v-overlay>
        <v-container class="pa-0 pa-md-2 pb-5">
            <v-row>
                <v-col class="col-sm" v-if="!isAuthUser && !paymentConfirmed">
                    <CheckoutLhs></CheckoutLhs>
                </v-col>
                <v-col class="col-sm guest-checkout" v-if="!togglePayment && !paymentConfirmed && !isAuthUser">
                    <div>
                        <div class="pt-10">
                            <GuestCheckoutDetailsForm
                                @isLoading="toggleLoading($event)"
                                @guestUserData="setGuestUserData($event)"></GuestCheckoutDetailsForm>
                        </div>
                        <div class="pt-10" v-if="!togglePayment">
                            <SecureLogin
                                @isLoading="toggleLoading($event)"
                                @isAuthenticated="resyncAuthUser()"></SecureLogin>
                        </div>
                    </div>
                </v-col>
                <v-col class="col-sm ml-5 guest-checkout" v-if="togglePayment && !isAuthUser">
                    Guest Checkout
                    <div class="pt-10">
                        <StripeWidget :booking-id="bookingId" @payment-successful="submitPayment"></StripeWidget>
                    </div>
                    <div class="pt-10">
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <UserPayment v-if="isAuthUser && togglePayment" @payment-successful="submitPayment"></UserPayment>
        <div v-if="paymentConfirmed">
            <PaymentConfirmation
                :email="userEmail"
                :bookingData="bookingData!.booking"></PaymentConfirmation>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.v-col.col-sm.guest-checkout {
    border-top: 3px solid var(--vt-c-orange);
}

.v-container {
  max-width: 1200px;
}

.cost-summary {
    font-weight: 500;
    .primary-button {
        width: 100%;
        text-align: center;
        font-size: 17px;
        font-weight: 500;
        &.invalid {
            pointer-events: none;
            background-color: var(--vt-c-disabled-button);
        }
    }
    .accept-conditions {
        display: inline-flex;
        line-height: 4;
    }
    h3 {
        color: var(--vt-c-deep-red);
        font-weight: 500;
    }
}
h2.title {
    color: var(--vt-c-deep-gray);
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
    padding-bottom: .625rem
}
.v-col.col-sm.guest-checkout {
    flex: none;
    width: 50%;
}
:deep(.v-overlay__content) {
    text-align: center;
    backdrop-filter: blur(2px);
}

@media only screen and (max-width: 760px) {
    .v-col.col-sm.guest-checkout {
        width: 100%;
    }
}
</style>
