<script setup lang="ts">
import { TransportModes } from "@/models/basket/TransportModes";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { JourneyModel, JourneySelectorViewModel } from "@/models/JourneySelectorViewModel";
import { JourneyUtilities } from "@/utilities/JourneyUtilities";
import { defineProps } from "vue";

const props = defineProps<{
    journeyModel: JourneyModel,
    journeySelectorViewModel: JourneySelectorViewModel
}>();

const involvesJourneyMode = (journeyModel: JourneyModel, mode: TransportModes): boolean => (
    JourneyUtilities.involvesTransportMode(journeyModel.journey.key, mode, props.journeySelectorViewModel)
);
</script>
<template>
    <div v-if="involvesJourneyMode(journeyModel, TransportModes.Bus)" class="journey-rrb pl-2">
        <a class="text-red" tabindex="0" data-toggle="tooltip" title="Involves at least one bus">
            <font-awesome-icon icon="fa-bus" class="fa-lg"></font-awesome-icon>
        </a>
    </div>
    <div v-if="involvesJourneyMode(journeyModel, TransportModes.Ship)" class="journey-ship pl-2">
        <a class="text-red" tabindex="0" data-toggle="tooltip"
           title="Involves at least one ship leg">
            <font-awesome-icon icon="fa-ship" class="fa-lg"></font-awesome-icon>
        </a>
    </div>
    <div v-if="involvesJourneyMode(journeyModel, TransportModes.Tube)" class="journey-tube pl-2">
        <a class="text-red" tabindex="0" data-toggle="tooltip"
           title="Involves at least one tube leg">
            <svg height="16" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round"
                 stroke-miterlimit="2" version="1.1" viewBox="0 0 615.3 500"
                 xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                <path d="m469.5 250c0 89.1-72.3 161.3-161.3 161.3-89.1 0-161.3-72.2-161.3-161.3s72.1-161.3 161.2-161.3 161.4 72.2 161.4 161.3m-161.4-250c-138.1 0-250 111.9-250 250s111.9 250 250 250 250-111.9 250-250-111.9-250-250-250" fill="#e1251f" fill-rule="nonzero"></path>
                <rect y="199.5" width="615.3" height="101.1" fill="#000f9f"></rect>
            </svg>
        </a>
    </div>
    <div v-if="involvesJourneyMode(journeyModel, TransportModes.Walk)" class="journey-walk pl-2">
        <a class="text-red" tabindex="0" data-toggle="tooltip"
           title="Involves at least one walking leg">
            <font-awesome-icon icon="fa-walking" class="fa-lg"></font-awesome-icon>
        </a>
    </div>
</template>
<style scoped lang="scss">
.journey-tube, .journey-rrb, .journey-ship, .journey-walk {
  display: inline-block;
  width: max-content;
}

.journey-rrb a, .journey-tube a, .journey-walk a {
  cursor: help;
}

.journey-tube svg {
  height: 1rem;
}
</style>
