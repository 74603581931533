<script setup lang="ts">

import {
    type Ref, ref, toRefs, watch,
} from "vue";
import type { GetJourneysRequest } from "@/models/remote/GetJourneysRequest";
import { useWindowSize } from "@vueuse/core";
import { retailService } from "@/services/RetailService";
import { clearAlerts, handleError } from "@/services/AlertService";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    addTime, formatTime, getDateDiff, toLongDate, toShortMonthDate,
} from "@/utilities/DateFunctions";
import { useTicketSummaryStore } from "@/stores/ticket-summary-store";
import { getJourneyFromKey } from "@/utilities/GetJourneyFromKey";
import type {
    GraphQLJourneyModel,
    JourneyModel,
    JourneySelectorViewModel,
    SelectedFare,
    TicketRestrictionSummary,
} from "@/models/JourneySelectorViewModel";
import { JourneyType } from "@/models/JourneyType";
import JourneyPlanner from "@/components/JourneyPlanner.vue";
import { JourneyTimeType } from "@/models/JourneyTimeType";
import JourneySummary from "@/components/3-Basket/JourneySummary.vue";
import { JourneyViewType } from "@/models/basket/JourneyViewType";
import type { SelectedJourney } from "@/models/basket/SelectedJourneyModel";
import { JourneyPlannerMode } from "@/models/JourneyPlannerMode";
import { useRouter } from "vue-router";
import { useJourneyStore } from "@/stores/journey-store";
import JourneyModeIcons from "@/components/1-JourneyPlanner/JourneyModeIcons.vue";

const props = defineProps<{
    request: GetJourneysRequest
}>();

const router = useRouter();
const store = useTicketSummaryStore();
const journeyStore = useJourneyStore();
const js: Ref = ref(null);
const { request } = toRefs(props);
const journeyQuery: GetJourneysRequest = request.value;
const { width } = useWindowSize();
const fromStationName: Ref<string> = ref("");
const toStationName: Ref<string> = ref("");

retailService
    .getPlace(journeyQuery.from)
    .then((x) => {
        fromStationName.value = x?.name!;
    })
    .catch((error) => {
        handleError(error);
    });

retailService
    .getPlace(journeyQuery.to)
    .then((x) => {
        toStationName.value = x?.name!;
    })
    .catch((error) => {
        handleError(error);
    });

const journeySelectorViewModel: Ref<JourneySelectorViewModel | undefined> = ref(undefined);
const journeyPlanner = ref<InstanceType<typeof JourneyPlanner> | null>(null);
const outboundJourneyDetails: Ref<GraphQLJourneyModel | null> = ref(null);
const inboundJourneyDetails: Ref<GraphQLJourneyModel | null> = ref(null);
const journeyTypeSelection: Ref<JourneyViewType> = ref(JourneyViewType.Inbound);
const displayReturn: Ref<boolean> = ref(false);
const emit = defineEmits(["searchResults", "selectedOutbound", "selectedReturn"]);
const isLoading: Ref<boolean> = ref(true);
const journeyPageCount: number = 5;
const showReturnColumn: Ref<boolean> = ref(journeyQuery.journeyType !== JourneyType.OneWay);
const selectedOutboundJourney: Ref<SelectedJourney | null> = ref(null);
const selectedReturnJourney: Ref<SelectedJourney | null> = ref(null);
const journeyPlannerMode: Ref<JourneyPlannerMode> = ref(JourneyPlannerMode.Compact);
const standardOutSelectedFareKey: Ref<string> = ref("");
const firstClassOutSelectedFareKey: Ref<string> = ref("");
const standardRetSelectedFareKey: Ref<string> = ref("");
const firstClassRetSelectedFareKey: Ref<string> = ref("");

watch(selectedOutboundJourney, (item: any) => {
    const journeyKey = item.journeyModel.journey.key;
    store.selectedOutboundJourneyKey.value = journeyKey;
    store.selectedOutboundFareType.value = item.fareType;
    store.selectedOutboundFurtherDetails.value = item.furtherDetails;
    store.selectedOutboundChanges.value = Number(item.journeyChanges);
    firstClassOutSelectedFareKey.value = item.journeyModel.firstClassFareKey;
    standardOutSelectedFareKey.value = item.journeyModel.standardFareKey;
    store.selectedOutbound.value = {
        fromName: journeySelectorViewModel.value!.fromName,
        toName: journeySelectorViewModel.value!.toName,
    };
});

watch(selectedReturnJourney, (item: any) => {
    const journeyKey = item.journeyModel.journey.key;
    store.selectedInboundJourneyKey.value = journeyKey;
    store.selectedReturnFareType.value = item.fareType;
    store.selectedInboundChanges.value = item.journeyChanges;
    store.selectedInboundFurtherDetails.value = item.furtherDetails;
    firstClassRetSelectedFareKey.value = item.journeyModel.firstClassFareKey;
    standardRetSelectedFareKey.value = item.journeyModel.standardFareKey;
    store.selectedInbound.value = {
        fromName: journeySelectorViewModel.value!.toName,
        toName: journeySelectorViewModel.value!.fromName,
    };
});

const isShowingMobileResults = () => width.value <= 760;
const storeStandardJourneyDetails = (journeyDetail: JourneyModel) => {
    outboundJourneyDetails.value = getJourneyFromKey(journeyDetail.journey.key, journeySelectorViewModel.value!.allJourneys!);
    const ticketDetailsKey = journeySelectorViewModel.value?.faresByFareKey[journeyDetail!.standardFareKey!].ticketDetailsKey;
    const selectedFareDetails = journeySelectorViewModel.value?.faresByFareKey[journeyDetail!.standardFareKey!];
    const ticketDetails = journeySelectorViewModel.value?.ticketDetailsByTicketDetailsKey[ticketDetailsKey!];
    const ticketRestrictionsSummary: Ref<TicketRestrictionSummary | null> = ref(null);
    if (selectedFareDetails?.restrictionCode !== "") {
        ticketRestrictionsSummary.value = journeySelectorViewModel.value!.ticketRestrictionsByRestrictionKey![selectedFareDetails!.restrictionCode]!;
    }
    emit("selectedOutbound", {
        journeyKey: journeyDetail.journey.key,
        fareKey: journeyDetail.standardFareKey,
        ticketDetails,
        selectedFare: selectedFareDetails,
        ticketRestrictions: ticketRestrictionsSummary.value,
        journeyDetails: outboundJourneyDetails.value,
        isOpenReturn: journeyStore.query.journeyType === JourneyType.OpenReturn,
    } as SelectedFare);
    store.selectedOutboundFare.value = journeyDetail.standardFare;
    store.selectedOutboundFurtherDetails.value = journeyDetail.furtherDetails!;
    store.outboundDetails.value = journeyDetail.journey;
};
const storeFirstClassJourneyDetails = (journeyDetail: JourneyModel) => {
    outboundJourneyDetails.value = getJourneyFromKey(journeyDetail.journey.key, journeySelectorViewModel.value!.allJourneys!);
    const ticketDetailsKey = journeySelectorViewModel.value?.faresByFareKey[journeyDetail!.firstClassFareKey!].ticketDetailsKey;
    const selectedFareDetails = journeySelectorViewModel.value?.faresByFareKey[journeyDetail!.firstClassFareKey!];
    const ticketDetails = journeySelectorViewModel.value?.ticketDetailsByTicketDetailsKey[ticketDetailsKey!];
    const ticketRestrictions: Ref<TicketRestrictionSummary | null> = ref(null);
    if (selectedFareDetails?.restrictionCode !== "") {
        ticketRestrictions.value = journeySelectorViewModel.value!.ticketRestrictionsByRestrictionKey![selectedFareDetails!.restrictionCode]!;
    }
    emit("selectedOutbound", {
        journeyKey: journeyDetail.journey.key,
        fareKey: journeyDetail.firstClassFareKey,
        ticketDetails,
        selectedFare: selectedFareDetails,
        ticketRestrictions: ticketRestrictions.value,
        journeyDetails: outboundJourneyDetails.value,
        isOpenReturn: journeyStore.query.journeyType === JourneyType.OpenReturn,
    } as SelectedFare);
    store.selectedOutboundFare.value = journeyDetail.firstClassFare!;
    store.selectedOutboundFurtherDetails.value = journeyDetail.firstClassFurtherDetails!;
    store.outboundDetails.value = journeyDetail.journey;
};
const storeReturnStandardJourneyDetails = (journeyDetail: JourneyModel) => {
    inboundJourneyDetails.value = getJourneyFromKey(journeyDetail.journey.key, journeySelectorViewModel.value!.allJourneys!);
    const ticketDetailsKey = journeySelectorViewModel.value?.faresByFareKey[journeyDetail!.standardFareKey!].ticketDetailsKey;
    const ticketDetails = journeySelectorViewModel.value?.ticketDetailsByTicketDetailsKey[ticketDetailsKey!];
    const selectedFareDetails = journeySelectorViewModel.value?.faresByFareKey[journeyDetail!.standardFareKey!];
    const ticketRestrictions: Ref<TicketRestrictionSummary | null> = ref(null);
    if (selectedFareDetails?.restrictionCode !== "") {
        ticketRestrictions.value = journeySelectorViewModel.value!.ticketRestrictionsByRestrictionKey![selectedFareDetails!.restrictionCode]!;
    }
    emit("selectedReturn", {
        journeyKey: journeyDetail.journey.key,
        fareKey: journeyDetail.standardFareKey,
        ticketDetails,
        selectedFare: selectedFareDetails,
        ticketRestrictions: ticketRestrictions.value,
        journeyDetails: inboundJourneyDetails.value,
    } as SelectedFare);
    store.selectedReturnFare.value = journeyDetail.standardFare;
    store.selectedInboundFurtherDetails.value = journeyDetail.furtherDetails!;
    store.inboundDetails.value = journeyDetail.journey;
};
const storeReturnFirstClassJourneyDetails = (journeyDetail: JourneyModel) => {
    inboundJourneyDetails.value = getJourneyFromKey(journeyDetail.journey.key, journeySelectorViewModel.value!.allJourneys!);
    const ticketDetailsKey = journeySelectorViewModel.value?.faresByFareKey[journeyDetail!.firstClassFareKey!].ticketDetailsKey;
    const ticketDetails = journeySelectorViewModel.value?.ticketDetailsByTicketDetailsKey[ticketDetailsKey!];
    const selectedFareDetails = journeySelectorViewModel.value?.faresByFareKey[journeyDetail!.firstClassFareKey!];
    const ticketRestrictions: Ref<TicketRestrictionSummary | null> = ref(null);
    if (selectedFareDetails?.restrictionCode !== "") {
        ticketRestrictions.value = journeySelectorViewModel.value!.ticketRestrictionsByRestrictionKey![selectedFareDetails!.restrictionCode]!;
    }
    emit("selectedReturn", {
        journeyKey: journeyDetail.journey.key,
        fareKey: journeyDetail.firstClassFareKey,
        ticketDetails,
        selectedFare: selectedFareDetails,
        ticketRestrictions: ticketRestrictions.value,
        journeyDetails: inboundJourneyDetails.value,
    } as SelectedFare);
    store.selectedReturnFare.value = journeyDetail.firstClassFare!;
    store.selectedInboundFurtherDetails.value = journeyDetail.firstClassFurtherDetails!;
    store.inboundDetails.value = journeyDetail.journey;
};

const setDefault = () => {
    const outboundKey = Object.keys(journeySelectorViewModel.value!.outbound!.journeys)[0];
    const journeys = journeySelectorViewModel.value?.outbound?.journeys[outboundKey];
    const foundOutbound = journeys!.find((element) => element.isCheapestStandardFare);
    selectedOutboundJourney.value = {
        fare: foundOutbound!.standardFare,
        fareType: foundOutbound!.standardFareLabel,
        furtherDetails: foundOutbound!.furtherDetails,
        isCheapest: true,
        journeyChanges: foundOutbound!.journey.changes,
        journeyModel: foundOutbound,
        journeyType: "standard",
        standardFareKey: foundOutbound?.standardFareKey,
    };
    storeStandardJourneyDetails(foundOutbound as JourneyModel);
    if (journeyQuery.journeyType === JourneyType.Return) {
        const inboundKey = Object.keys(journeySelectorViewModel.value!.inbound!.journeys)[0];
        const journeysReturn = journeySelectorViewModel.value?.inbound?.journeys[inboundKey];
        const foundReturn = journeysReturn!.find((element) => element.isCheapestStandardFare);
        selectedReturnJourney.value = {
            fare: foundReturn!.standardFare,
            fareType: foundReturn!.standardFareLabel,
            furtherDetails: foundReturn?.furtherDetails,
            isCheapest: true,
            journeyChanges: foundReturn!.journey.changes,
            journeyModel: foundReturn,
            journeyType: "standard",
            standardFareKey: foundReturn?.standardFareKey,
        };
        storeReturnStandardJourneyDetails(foundReturn as JourneyModel);
    }

    if (journeyQuery.journeyType === JourneyType.OneWay || journeyQuery.journeyType === JourneyType.OpenReturn) {
        emit("selectedReturn", null);
    }
};

const clonedRequest: GetJourneysRequest = structuredClone(journeyQuery);

async function performSearch(getJourneysRequest: GetJourneysRequest) {
    isLoading.value = true;
    emit("searchResults", null);
    await retailService
        .getJourneys(journeyPageCount, getJourneysRequest)
        .then((x) => {
            journeySelectorViewModel.value = x;
            emit("searchResults", journeySelectorViewModel);
            clearAlerts();
        })
        .catch((error) => {
            handleError(error);
        })
        .finally(() => {
            isLoading.value = false;
        });
    if (!isLoading.value) {
        setDefault();
    }
}

function viewEarlierOutboundJourney() {
    // We want to go earlier for the outward time only
    // Perform new search of **arrive before** 1 minute before the first journey's arrival time

    // Example: First journey in results page is 18:50 - 19:12, let's say we did a depart-after of 18:45
    // and we want to go earlier - so new search time is 19:11 in "arrive before" mode.

    if (journeySelectorViewModel.value?.outbound?.journeys !== undefined
        && Object.keys(journeySelectorViewModel.value?.outbound?.journeys).length > 0) {
        const firstKey = Object.keys(journeySelectorViewModel.value!.outbound!.journeys)[0];
        const firstJourney = journeySelectorViewModel.value?.outbound?.journeys[firstKey][0];

        clonedRequest.outboundTimeType = JourneyTimeType.ArrivingBefore;
        clonedRequest.outboundDate = addTime(firstJourney?.journey.arriving, -1, "m").toISOString();

        performSearch(clonedRequest);
    }
}

function viewEarlierReturnJourney() {
    // We want to go earlier for the outward time only
    // Perform new search of **arrive before** 1 minute before the first journey's arrival time

    // Example: First journey in results page is 18:50 - 19:12, let's say we did a depart-after of 18:45
    // and we want to go earlier - so new search time is 19:11 in "arrive before" mode.

    if (journeySelectorViewModel.value?.inbound?.journeys !== undefined
        && Object.keys(journeySelectorViewModel.value?.inbound?.journeys).length > 0) {
        const firstKey = Object.keys(journeySelectorViewModel.value!.inbound!.journeys)[0];
        const firstJourney = journeySelectorViewModel.value?.inbound?.journeys[firstKey][0];

        clonedRequest.returnTimeType = JourneyTimeType.ArrivingBefore;
        clonedRequest.returnDate = addTime(firstJourney?.journey.arriving, -1, "m").toISOString();

        performSearch(clonedRequest);
    }
}

function viewLaterOutboundJourney() {
    // We want to go later for the outward time only
    // Perform new search of **depart after** 1 minute after the last journey's departure time

    // Example: Last journey in result page is 18:50 - 19:12
    // and we want to go later - so new search time is 19:13 in "depart after" mode

    if (journeySelectorViewModel.value?.outbound?.journeys !== undefined
        && Object.keys(journeySelectorViewModel.value?.outbound?.journeys).length > 0) {
        const journeyDictionaryLength = Object.keys(journeySelectorViewModel.value!.outbound!.journeys).length;
        const lastKey = Object.keys(journeySelectorViewModel.value!.outbound!.journeys)[journeyDictionaryLength - 1];
        const lastJourneyDictionaryItem = journeySelectorViewModel.value?.outbound?.journeys[lastKey];

        const lastJourney = lastJourneyDictionaryItem[lastJourneyDictionaryItem.length - 1];

        clonedRequest.outboundTimeType = JourneyTimeType.DepartingAt;
        clonedRequest.outboundDate = addTime(lastJourney?.journey.departing, 1, "m").toISOString();

        performSearch(clonedRequest);
    }
}

function viewLaterReturnJourney() {
    if (journeySelectorViewModel.value?.inbound?.journeys !== undefined
        && Object.keys(journeySelectorViewModel.value?.inbound?.journeys).length > 0) {
        const journeyDictionaryLength = Object.keys(journeySelectorViewModel.value!.inbound!.journeys).length;
        const lastKey = Object.keys(journeySelectorViewModel.value!.inbound!.journeys)[journeyDictionaryLength - 1];
        const lastJourneyDictionaryItem = journeySelectorViewModel.value?.inbound?.journeys[lastKey];

        const lastJourney = lastJourneyDictionaryItem[lastJourneyDictionaryItem.length - 1];

        clonedRequest.returnTimeType = JourneyTimeType.DepartingAt;
        clonedRequest.returnDate = addTime(lastJourney?.journey.departing, 1, "m").toISOString();

        performSearch(clonedRequest);
    }
}

async function showJourneySummary(key: string, journeyType: JourneyViewType) {
    if (journeyType === JourneyViewType.Outbound) {
        outboundJourneyDetails.value = getJourneyFromKey(key, journeySelectorViewModel.value!.allJourneys!);
    } else {
        inboundJourneyDetails.value = getJourneyFromKey(key, journeySelectorViewModel.value!.allJourneys!);
    }
    journeyTypeSelection.value = journeyType;
    js.value.showDialog();
}

function base64EncodeValue(value: string): string {
    return btoa(value);
}

function journeyDateDifferentToQueryDate(value: string): boolean {
    return new Date(Date.parse(value)).getDate() !== new Date(props.request.outboundDate).getDate();
}

function toggleReturn() {
    displayReturn.value = !displayReturn.value;
}

const storeAndContinue = (journeyDetail: JourneyModel) => {
    if (isShowingMobileResults()) {
        storeStandardJourneyDetails(journeyDetail);
        selectedOutboundJourney.value = {
            fare: journeyDetail!.standardFare,
            fareType: journeyDetail!.standardFareLabel,
            furtherDetails: journeyDetail?.furtherDetails,
            isCheapest: journeyDetail?.isCheapestStandardFare,
            journeyChanges: journeyDetail!.journey.changes,
            journeyModel: journeyDetail,
            journeyType: "standard",
        };
        if (showReturnColumn.value) {
            toggleReturn();
        } else {
            router.push({ path: "/your-selection/" });
        }
    }
};

function setDate() {
    return toLongDate(journeyQuery?.returnDate!);
}

const syncStoreAndPinia = () => {
    journeyPlanner.value?.syncStoreAndPinia();
};

function toggleJourneyPlanner() {
    journeyPlanner.value!.forceOpenPanel();
    syncStoreAndPinia();
    journeyPlannerMode.value = JourneyPlannerMode.Default;
}

function clearOldSearch() {
    emit("selectedReturn", null);
    emit("selectedOutbound", null);
    standardOutSelectedFareKey.value = "";
    standardRetSelectedFareKey.value = "";
    firstClassOutSelectedFareKey.value = "";
    firstClassRetSelectedFareKey.value = "";
    store.$reset();
}

performSearch(journeyQuery);

defineExpose({
    syncStoreAndPinia,
});
</script>

<template>
    <div>
        <div class="journey-planner">
            <JourneyPlanner :mode="journeyPlannerMode" :enableExpand="true" :selectedOutbound="fromStationName"
                            ref="journeyPlanner"
                            :selectedReturn="toStationName"
                            @new-search="clearOldSearch()"></JourneyPlanner>
        </div>
        <div id="journey-selection-container" class="ps-2 pt-5">
            <div class="text-center summary">
            </div>
            <v-overlay v-model="isLoading"
                       contained
                       class="align-center justify-center">
                <v-progress-circular
                    indeterminate
                    :size="70"
                    :width="7"
                    color="primary">
                </v-progress-circular>
            </v-overlay>

            <v-row>
                <v-col id="outbound-column" :class="[displayReturn ? 'active-return' : '', 'pl-5']">
                    <RouterLink class="nav-link" to="/">
                        <font-awesome-icon icon="fa-arrow-left"></font-awesome-icon>
                        Search
                    </RouterLink>
                    <div id="outbound-header">
                        <div class="text-h6">Out</div>
                        <div class="font-weight-bold outboundDate">{{ toLongDate(journeyQuery?.outboundDate) }}</div>
                        <div>{{ fromStationName }} to {{ toStationName }}</div>
                    </div>

                    <div id="outbound-earlier-journeys">

                        <div class="pt-2 pb-2">
                            <a @click="viewEarlierOutboundJourney()">
                                <font-awesome-icon icon="fa-chevron-up"></font-awesome-icon>
                                <span class="ps-3">Earlier Journeys</span>
                            </a>
                        </div>

                    </div>

                    <div id="outbound-results">

                        <div v-if="journeySelectorViewModel">

                            <v-table>
                                <thead>
                                    <tr>
                                        <th>
                                        </th>
                                        <th style="min-width: 130px;" class="text-center standard">
                                            Standard
                                        </th>
                                        <th style="min-width: 130px;" class="text-center first-class">
                                            1st Class
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-for="(item, key) in journeySelectorViewModel?.outbound?.journeys"
                                       :key="key">
                                    <tr class="table-group" v-if="journeyDateDifferentToQueryDate(key.toString())">
                                        <td colspan="3">
                                            {{ toShortMonthDate(key.toString()) }}
                                        </td>
                                    </tr>
                                    <tr
                                        v-for="journeyModel in item"
                                        :key="journeyModel.journey.key"
                                        :data-key="base64EncodeValue(journeyModel.journey.key)"
                                        class="summary-row"
                                        @click="storeAndContinue(journeyModel)">
                                        <td class="journey-details" style="width: 210px;">
                                            <div class="depart-arrive-time mt-2">
                                                <span class="pe-3 outbound-departing-time">
                                                    {{ formatTime(journeyModel.journey.departing) }}
                                                </span>
                                                <font-awesome-icon icon="fa-arrow-right"></font-awesome-icon>
                                                <span class="ps-3 outbound-arriving-time">
                                                    {{ formatTime(journeyModel.journey.arriving) }}
                                                </span>
                                            </div>
                                            <div>
                                                <span class="ps-2 pe-2">
                                                    {{
                                                        getDateDiff(journeyModel.journey.departing, journeyModel.journey.arriving)
                                                    }}
                                                </span>

                                                <a @click='showJourneySummary(journeyModel.journey.key, JourneyViewType.Outbound)'>
                                                    <span v-if="journeyModel.journey.changes === 0">
                                                        Direct
                                                    </span>
                                                    <span v-else>
                                                        {{ journeyModel.journey.changes }} change<span
                                                            v-if="journeyModel.journey.changes > 1">s</span>
                                                    </span>
                                                </a>
                                            </div>
                                            <div v-if="journeyModel.isOvertakenByAnotherService" class="journey-overtaken pl-2">
                                                <a tabindex="0" data-toggle="tooltip" title="Overtaken by later journey">
                                                    <font-awesome-icon icon="fa-hourglass" class="fa-lg orange"></font-awesome-icon>
                                                </a>
                                            </div>
                                            <JourneyModeIcons :journey-model="journeyModel" :journey-selector-view-model="journeySelectorViewModel!"></JourneyModeIcons>
                                        </td>
                                        <td class="text-center align-top standard-option">
                                            <v-radio-group v-model="selectedOutboundJourney">
                                                <v-radio density="comfortable"
                                                         :value="{
                                                             journeyModel: journeyModel,
                                                             journeyType: 'standard',
                                                             isCheapest: journeyModel.isCheapestStandardFare,
                                                             journeyChanges: journeyModel.journey.changes,
                                                             fare: journeyModel.standardFare,
                                                             fareType: journeyModel.standardFareLabel,
                                                             furtherDetails: journeyModel.furtherDetails,
                                                             standardFareKey: journeyModel.standardFareKey,
                                                         }"
                                                         :label="journeyModel.standardFareDisplay"
                                                         @click="storeStandardJourneyDetails(journeyModel)"></v-radio>

                                            </v-radio-group>
                                            <div class="cheapest-container ml-5" v-if="journeyModel.isCheapestStandardFare">
                                                Cheapest
                                            </div>
                                        </td>
                                        <td class="text-center align-top first-option">
                                            <div v-if="journeyModel.firstClassFare">
                                                <v-radio-group v-model="selectedOutboundJourney">
                                                    <v-radio density="comfortable"
                                                             :value="{
                                                                 journeyModel: journeyModel,
                                                                 journeyType: 'first',
                                                                 isCheapest: journeyModel.isCheapestFirstClassFare,
                                                                 journeyChanges: journeyModel.journey.changes,
                                                                 fare: journeyModel.firstClassFare,
                                                                 fareType: journeyModel.firstClassFareLabel,
                                                                 furtherDetails: journeyModel.firstClassFurtherDetails,
                                                                 firstClassFareKey: journeyModel.firstClassFareKey
                                                             }"
                                                             :label="journeyModel.firstClassFareDisplay"
                                                             @click="storeFirstClassJourneyDetails(journeyModel)"></v-radio>
                                                </v-radio-group>
                                                <div class="cheapest-container ml-5"
                                                     v-if="journeyModel.isCheapestFirstClassFare">
                                                    Cheapest
                                                </div>

                                            </div>
                                        </td>
                                        <div class="divider"></div>
                                    </tr>
                                </tbody>
                            </v-table>

                        </div>

                    </div>

                    <div id="outbound-later-journeys">

                        <div class="pt-2 pb-2">
                            <a @click="viewLaterOutboundJourney()">
                                <font-awesome-icon icon="fa-chevron-down"></font-awesome-icon>
                                <span class="ps-3">Later Journeys</span>
                            </a>
                        </div>

                    </div>

                </v-col>
                <v-col id="return-column" :class="[displayReturn ? 'active-return' : '']">
                    <a class="nav-link" @click="toggleReturn">
                        <font-awesome-icon icon="fa-arrow-left"></font-awesome-icon>
                        Outbound
                    </a>
                    <div v-if="showReturnColumn" class="return-results float-right mr-5">
                        <RouterLink class="nav-link" to="/your-selection">
                            <a class="nav-link">Your Selection
                                <font-awesome-icon icon="fa-arrow-right"></font-awesome-icon>
                            </a>
                        </RouterLink>
                    </div>
                    <div id="return-header">
                        <div class="text-h6">Return</div>
                        <div v-if="journeyQuery.journeyType === JourneyType.Return"
                             class="font-weight-bold outboundDate">{{ setDate() }}
                        </div>
                        <div>{{ toStationName }} to {{ fromStationName }}</div>
                    </div>
                    <div id="return-header">

                    </div>

                    <div id="return-earlier-journeys" class="pt-2 pb-2" v-if="showReturnColumn">
                        <a @click="viewEarlierReturnJourney()">
                            <font-awesome-icon icon="fa-chevron-up"></font-awesome-icon>
                            <span class="ps-3">Earlier Journeys</span>
                        </a>
                    </div>

                    <div id="return-results" v-if="!showReturnColumn" @click="toggleJourneyPlanner" class="pt-10">
                        <button>
                            <font-awesome-icon icon="fa-circle-plus"></font-awesome-icon>
                            Add a return journey
                        </button>
                    </div>

                    <div id="return-result-items" v-if="showReturnColumn">
                        <v-table>
                            <thead v-if="journeySelectorViewModel">
                                <tr>
                                    <th>
                                    </th>
                                    <th style="min-width: 130px;" class="text-center standard">
                                        Standard
                                    </th>
                                    <th style="min-width: 130px;" class="text-center first-class">
                                        1st Class
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="(item, key) in journeySelectorViewModel?.inbound?.journeys"
                                   :key="key">
                                <tr
                                    v-for="journeyModel in item"
                                    :key="journeyModel.journey.key"
                                    :data-key="base64EncodeValue(journeyModel.journey.key)"
                                    class="summary-row">
                                    <td class="journey-details" style="width: 210px;">
                                        <div class="depart-arrive-time mt-2">
                                            <span class="pe-3 outbound-departing-time">
                                                {{ formatTime(journeyModel.journey.departing) }}
                                            </span>
                                            <font-awesome-icon icon="fa-arrow-right"></font-awesome-icon>
                                            <span class="ps-3 outbound-arriving-time">
                                                {{ formatTime(journeyModel.journey.arriving) }}
                                            </span>
                                        </div>
                                        <div class="journey-summary d-flex">
                                            <span class="pe-2">
                                                {{
                                                    getDateDiff(journeyModel.journey.departing, journeyModel.journey.arriving)
                                                }}
                                            </span>

                                            <a @click='showJourneySummary(journeyModel.journey.key, JourneyViewType.Inbound)'>
                                                <span v-if="journeyModel.journey.changes === 0">
                                                    Direct
                                                </span>
                                                <span v-else>
                                                    {{ journeyModel.journey.changes }} change<span
                                                        v-if="journeyModel.journey.changes > 1">s</span>
                                                </span>
                                            </a>
                                            <div v-if="journeyModel.isOvertakenByAnotherService" class="journey-overtaken pl-2">
                                                <a tabindex="0" data-toggle="tooltip" title="Overtaken by later journey">
                                                    <font-awesome-icon icon="fa-hourglass" class="fa-lg orange"></font-awesome-icon>
                                                </a>
                                            </div>
                                        </div>
                                        <JourneyModeIcons :journey-model="journeyModel" :journey-selector-view-model="journeySelectorViewModel!"></JourneyModeIcons>
                                    </td>
                                    <td class="text-center align-top standard-option">
                                        <v-radio-group v-model="selectedReturnJourney">
                                            <v-radio density="comfortable"
                                                     :value="{
                                                         journeyModel: journeyModel,
                                                         journeyType: 'standard',
                                                         isCheapest: journeyModel.isCheapestStandardFare,
                                                         journeyChanges: journeyModel.journey.changes,
                                                         fare: journeyModel.standardFare,
                                                         fareType: journeyModel.standardFareLabel,
                                                         furtherDetails: journeyModel.furtherDetails,
                                                         standardFareKey: journeyModel.standardFareKey
                                                     }"
                                                     :label="journeyModel.standardFareDisplay"
                                                     @click="storeReturnStandardJourneyDetails(journeyModel)"></v-radio>

                                        </v-radio-group>
                                        <div class="cheapest-container ml-5" v-if="journeyModel.isCheapestStandardFare">
                                            Cheapest
                                        </div>
                                    </td>
                                    <td class="text-center align-top first-option">
                                        <div v-if="journeyModel.firstClassFare">
                                            <v-radio-group v-model="selectedReturnJourney">
                                                <v-radio density="comfortable"
                                                         :value="{
                                                             journeyModel: journeyModel,
                                                             journeyType: 'first',
                                                             isCheapest: journeyModel.isCheapestFirstClassFare,
                                                             journeyChanges: journeyModel.journey.changes,
                                                             fare: journeyModel.firstClassFare,
                                                             fareType: journeyModel.firstClassFareLabel,
                                                             furtherDetails: journeyModel.firstClassFurtherDetails,
                                                             firstClassFareKey: journeyModel.firstClassFareKey
                                                         }"
                                                         :label="journeyModel.firstClassFareDisplay"
                                                         @click="storeReturnFirstClassJourneyDetails(journeyModel)"></v-radio>
                                            </v-radio-group>
                                            <div class="cheapest-container ml-5"
                                                 v-if="journeyModel.isCheapestFirstClassFare">
                                                Cheapest
                                            </div>

                                        </div>
                                    </td>
                                    <div class="divider"></div>
                                </tr>
                            </tbody>
                        </v-table>

                    </div>

                    <div id="return-later-journeys" v-if="showReturnColumn">

                        <div class="pt-2 pb-2">
                            <a @click="viewLaterReturnJourney()">
                                <font-awesome-icon icon="fa-chevron-down"></font-awesome-icon>
                                <span class="ps-3">Later Journeys</span>
                            </a>
                        </div>

                    </div>

                </v-col>
            </v-row>
            <JourneySummary ref="js" :inboundJourney="inboundJourneyDetails" :outboundJourney="outboundJourneyDetails" :journey-type="journeyTypeSelection"></JourneySummary>
        </div>
    </div>
</template>

<style lang="css">

.v-table__wrapper > table > tbody > tr > td,
.v-table__wrapper > table > thead > tr > td {
    height: 90px !important;
    vertical-align: top;
    border-bottom: 0 !important;
}

@media (max-width: 2559px) {
    .journey-selection.v-container {
        max-width: 1850px;
    }
}
.v-table__wrapper > table > thead > tr > th {
    border-bottom: 0 !important;
}

#outbound-earlier-journeys, #return-earlier-journeys {
    min-height: 40px;
}

#outbound-results, #return-result-items {
    min-height: 510px;
}

div#journey-selection-container {
    display: flex;
    width: 65vw;
    float: left;
    margin-left: 1.25rem;
}

@media (min-width: 1137px) {
    div#journey-selection-container {
        /* Important: keep space for Your Selection which unfortunately has a fixed width */
        /* And it *outside* of the hierarchy of these elements */
        /* TODO: a more sensible element hierarchy and use of CSS grid to lay out these elements */
        width: calc(100% - 500px);
    }
}

.v-input__control .v-selection-control .v-label {
    word-break: keep-all;
}
</style>

<style scoped lang="scss">
:deep(.v-divider) {
  opacity: 100;
}

:deep(.cheapest-container) {
  margin-top: 5px;
  border-top: solid 2px;
  color: var(--vt-c-orange);
  width: 60%;
}

.v-input--horizontal {
  justify-content: start;
}

.table-group > td {
  height: 40px !important;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  border-top: 2px solid var(--color-background-mute) !important;
  border-bottom: 2px solid var(--color-background-mute) !important;
}

:deep(tr:has(.mdi-radiobox-marked) label),
:deep(tr:has(.mdi-radiobox-marked) span) {
  font-weight: bold;
}

a.nav-link {
  display: none;
}

.v-row {
  justify-content: center;
}

:deep(.v-selection-control__wrapper) {
  margin-left: 20px;
}

div#outbound-header,
div#outbound-earlier-journeys,
div#outbound-later-journeys,
div#return-earlier-journeys,
div#return-later-journeys,
tbody {
  font-size: 1rem;
}

th {
  font-size: 1.05rem;
}

div#outbound-results {
  border-right: 3px solid var(--vt-light-gray);
}

div#return-header {
    font-size: 1rem;
}
div#outbound-later-journeys,
div#outbound-earlier-journeys {
  border-right: 3px solid var(--vt-light-gray);
}

.summary label {
  font-size: 1.2rem;
}

div#return-result-items {
  margin-left: -24px;
  padding-bottom: 2px;
}
.journey-overtaken.pl-2 a {
    cursor: help;
}

.journey-overtaken {
  display: inline-block;
  width: max-content;
}

:deep(.v-input__control .v-selection-control-group) {
    flex-direction: row;
}
@media only screen and (max-width: 760px) {
  div#return-column {
    display: none;

    &.active-return {
      display: inline-table;
    }
  }
  div#outbound-column {
    display: block;

    &.active-return {
      display: none;
    }
  }
  .return-results.float-right {
    margin-top: -25px;
  }
  div#outbound-column {
    max-width: 715px;
  }
  .depart-arrive-time {
    width: 170px;
    font-size: 16px;
    padding-top: 5px;
  }
  div#outbound-later-journeys,
  div#return-earlier-journeys,
  div#return-later-journeys,
  div#outbound-earlier-journeys,
  div#outbound-header {
    text-align: center;
  }
  td.text-center.align-top.first-option,
  th.text-center.first-class {
    display: none;
  }
  .mdi-radiobox-blank.mdi.v-icon.notranslate.v-icon--size-default {
    display: none;
  }
  :deep(.v-selection-control-group) {
    align-items: center;
  }
  .v-selection-control-group .v-selection-control--dirty {
    background-color: var(--vt-c-orange);
  }
  .v-selection-control__input {
    display: none;
  }
  .v-selection-control .v-icon {
    display: none;
  }
  :deep(.v-selection-control__wrapper) {
    display: none;
  }
  div#outbound-header {
    padding-top: 10px;
  }
  .v-selection-control.v-selection-control--density-comfortable.v-radio {
    border: 2px solid var(--vt-c-orange);
    border-radius: 20px;
    width: fit-content;
    display: inline-block;
  }
  .cheapest-container {
    border: none;
  }
  svg.svg-inline--fa.fa-clock,
  svg.svg-inline--fa.fa-arrow-right-arrow-left {
    display: none;
  }
  #outbound-results td, #return-result-items td {
    border-top: 1px solid var(--vt-c-lightgray);
    padding-top: 20px;
  }
  div#return-result-items {
    padding-top: 0px;
    width: 100vw;
    margin-left: 0px;
  }
  tr.table-group {
    display: none;
  }
  thead {
    display: none;
  }
  .v-selection-control-group {
    height: 39px;
  }
  #outbound-results, #return-column {
    width: 100vw;
  }
  :deep(.v-selection-control .v-label) {
    width: auto;
    margin: 0 1.5rem;
    height: 35px;
    font-weight: bold;
  }
  .v-container {
    display: none;
  }
  #outbound-results, #return-results {
    min-height: fit-content;
  }
  a.nav-link {
    display: block;
    font-size: 17px;
  }
  div#journey-selection-container {
    width: 100vw;
    margin-left: 0rem;
  }
}

:deep(.v-input__details) {
  display: none;
}

</style>
