<template>
    <v-container>
        <v-row no-gutter class="mb-4">
            <v-col cols="12" v-for="field in sortedQuestions" :key="field.id">
                <!-- Dropdown Question -->
                <v-select
                    v-if="field.type === QuestionType.Dropdown"
                    v-model="field.modelValue"
                    :label="(field as DropdownQuestion).question"
                    :items="(field as DropdownQuestion).possibleAnswers"
                    :hint="field.helperText ?? undefined"
                    :persistent-hint="true"
                    :error-messages="field.underlyingVeeValidateField.errorMessage"
                    :clearable="!field.mandatory"
                ></v-select>
                <!-- Free Text Question -->
                <v-text-field
                    v-else-if="field.type === QuestionType.FreeText"
                    v-model="field.modelValue"
                    :label="field.question"
                    :placeholder="(field as FreeTextQuestion).placeholderText || ''"
                    :hint="field.helperText ?? undefined"
                    :persistent-hint="true"
                    :error-messages="field.underlyingVeeValidateField.errorMessage"
                    clearable
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import {
    ref, defineProps, defineExpose, onUpdated,
} from "vue";
import type { Ref } from "vue";
import { useField, useForm } from "vee-validate";
import type { FieldContext } from "vee-validate";

import type { ManagementInformationQuestionsResponse, DropdownQuestion, FreeTextQuestion } from "@/models/graphql/ManagementInformationQuestionsTypes";

enum QuestionType {
    Dropdown = "Dropdown",
    FreeText = "FreeText",
}

const props = defineProps<{
        questions: ManagementInformationQuestionsResponse;
}>();

type FrontendQuestionModel = ({
    question: string;
    defaultValue?: string | null;
    underlyingVeeValidateField: FieldContext<any>;
    modelValue: Ref<any>;
    id: string;
    priority: number;
    possibleAnswers: string[];
    type: QuestionType;
    mandatory: boolean;
    helperText?: string | null;
    enabled: boolean;
} | {
    question: string;
    defaultValue?: string | null;
    underlyingVeeValidateField: FieldContext<string>;
    modelValue: Ref<string>;
    placeholderText?: string | null;
    id: string;
    priority: number;
    type: QuestionType;
    mandatory: boolean;
    helperText?: string | null;
    enabled: boolean;
});

const { handleSubmit } = useForm();
const sortedQuestions = ref<FrontendQuestionModel[]>([]);

function initialiseQuestions() {
    const dropdownQuestions = props.questions.dropdownQuestions.map((q) => {
        const field = useField(q.id, (answer: any) => {
            if (q.mandatory) {
                return !!answer || "This field is required.";
            }

            if (!q.possibleAnswers.includes(answer)) {
                return "Invalid answer, not in permitted possible answers list.";
            }

            return true;
        }, {
            initialValue: q.defaultValue ?? (q.possibleAnswers.length > 0 ? q.possibleAnswers[0] : undefined),
            label: q.question,
        });
        return ({
            ...q,
            modelValue: field.value,
            underlyingVeeValidateField: field,
            type: QuestionType.Dropdown,
        });
    });
    const freeTextQuestions = props.questions.freeTextQuestions.map((q) => {
        const field = useField(q.id, q.mandatory ? "required" : () => true, {
            initialValue: q.defaultValue ?? undefined,
            label: q.question,
        });
        return ({
            ...q,
            type: QuestionType.FreeText,
            modelValue: field.value,
            underlyingVeeValidateField: field,
        });
    });

    sortedQuestions.value = [...dropdownQuestions, ...freeTextQuestions].sort(
        (a, b) => a.priority - b.priority,
    );
}

onUpdated(initialiseQuestions);
initialiseQuestions();

const getAnswers = handleSubmit((values) => values);

defineExpose({ getAnswers });
</script>
<style lang="scss" scoped>
:deep(input[type="text"]) {
  padding-inline: var(--v-field-padding-start) var(--v-field-padding-end);
  padding-top: var(--v-field-input-padding-top);
  padding-bottom: var(--v-field-input-padding-bottom);
}
</style>
