import { loadStripe } from "@stripe/stripe-js";
import type { StripeConstructorOptions } from "@stripe/stripe-js";
import { ref } from "vue";
import type { Ref } from "vue";
import { defineStore } from "pinia";

// public record StripeConfiguration(string PublishableKey, string? AccountId);

export interface StripeConfiguration {
    publishableKey: string;
    accountId?: string;
}

export const usePaymentStore = defineStore("payment", () => {
    const stripeReady: Ref<boolean> = ref(false);

    const stripePublishableKey: Ref<string> = ref("");
    const stripeAccountId: Ref<string|null> = ref(null);

    async function initialiseStripe() {
        const stripeConfiguration = await fetch("/api/stripe-conf").then((response) => response.json() as Promise<StripeConfiguration>);
        stripePublishableKey.value = stripeConfiguration.publishableKey;
        let additionalOpts: StripeConstructorOptions|undefined;
        if (stripeConfiguration.accountId) {
            additionalOpts = {
                stripeAccount: stripeConfiguration.accountId,
            };
            stripeAccountId.value = stripeConfiguration.accountId;
        }
        const stripePromise = loadStripe(stripeConfiguration.publishableKey, additionalOpts);
        stripePromise.then(() => {
            stripeReady.value = true;
        });
    }

    return {
        stripeReady,
        stripePublishableKey,
        stripeAccountId,
        initialiseStripe,
    };
});
