<script setup lang="ts">
import { useForm } from "vee-validate";
import {
    ref, toRefs, watch, onMounted, inject, computed,
} from "vue";
import type { Ref } from "vue";
import { useJourneyStore } from "@/stores/journey-store";
import { useTicketSummaryStore } from "@/stores/ticket-summary-store";
import { validateOriginDestination } from "@/validators/OriginDestinationValidator";
import { validateOutboundReturnDate } from "@/validators/OutboundReturnDateValidator";
import { validateRailcardAndPassenger } from "@/validators/RailcardPassengerValidator";
import { validateViaAvoid } from "@/validators/ViaAvoidValidator";
import type { ValidationError } from "@/validators/ValidationError";
import type { GetJourneysRequest } from "@/models/remote/GetJourneysRequest";
import { useWindowSize } from "@vueuse/core";
import { JourneyType } from "@/models/JourneyType";
import { JourneyPlannerMode } from "@/models/JourneyPlannerMode";
import { useRoute, useRouter } from "vue-router";
import { DateTime } from "luxon";
import { retailInstanceTypeSymbol } from "@/models/Symbols";
import type { RetailInstanceTypeService } from "@/services/RetailInstanceTypeService";
import { useBookingData } from "@/stores/booking-storage";
import { gqlBookingService } from "@/services/GQLService";
import { handleError } from "@/services/AlertService";
import ValidationSummary from "./ValidationSummary.vue";
import ViaAvoidSelector from "./1-JourneyPlanner/ViaAvoidSelector.vue";
import PassengerSelector from "./1-JourneyPlanner/PassengerSelector.vue";
import OutboundReturnDateSelector from "./1-JourneyPlanner/OutboundReturnDateSelector.vue";
import OriginDestinationSelector from "./1-JourneyPlanner/OriginDestinationSelector.vue";

const props = defineProps<{
  mode: JourneyPlannerMode,
  enableExpand: boolean,
  selectedOutbound?: string,
  selectedReturn?: string,
  journeyType?: JourneyType
}>();

const { width } = useWindowSize();
const store = useJourneyStore();
const ticketSummaryStore = useTicketSummaryStore();
const route = useRoute();
const router = useRouter();
const emit = defineEmits(["new-search"]);
const { mode } = toRefs(props);
const isMobile: Ref<boolean> = ref(false);
const hideSummary: Ref<boolean> = ref(false);
const journeySearchData: string | null = localStorage.getItem("journey-search");
const showingViaAvoidSelector: Ref<boolean> = ref(false);
const openedPanel: Ref<string | null> = ref(mode.value === "compact" ? null : "header");
const isEmbedded: Ref<boolean> = ref(false);
const embeddedTarget: Ref<string> = ref("");
const isLoading: Ref<boolean> = ref(false);

if (journeySearchData !== null) {
    store.$patch(JSON.parse(journeySearchData));
}

function closeModal() {
    hideSummary.value = true;
}

store.$subscribe((mutation, state) => {
    localStorage.setItem("journey-search", JSON.stringify(state));
});

watch(width, () => {
    isMobile.value = width.value < 760;
});

onMounted(() => {
    isMobile.value = width.value < 760;
    if ("hide-nav" in route.query && route.query["hide-nav"] === "true") {
        isEmbedded.value = true;
        embeddedTarget.value = "_top";
    }
});

function getInitialValuesFromStore() {
    return {
        originStation: store.query.originStation,
        destinationStation: store.query.destinationStation,
        outboundDate: store.query.outboundDate,
        outboundHour: store.query.outboundDate?.getHours(),
        outboundMinute: store.query.outboundDate?.getMinutes(),
        outboundTimeType: store.query.outboundTimeType,
        returnDate: store.query.returnDate,
        returnHour: store.query.returnDate?.getHours(),
        returnMinute: store.query.returnDate?.getMinutes(),
        returnTimeType: store.query.returnTimeType,
        journeyType: store.query.journeyType,
        adults: store.query.adults,
        children: store.query.children,
        railCard: store.query.railCard,
        numberOfRailcards: store.query.railCardQuantity,
        viaAvoidStation: store.query.viaAvoidStation,
        viaAvoid: store.query.viaAvoid,
    };
}

const initialValues = getInitialValuesFromStore();

const errorsFromSubmit: Ref<Partial<Record<never, string>> | null> = ref(null);
const { handleSubmit, setErrors, resetForm } = useForm({ initialValues });

const syncStoreAndPinia = () => {
    resetForm({
        values: getInitialValuesFromStore(),
    });
};

function displayValidationErrors(validationMessages: ValidationError[], logErrorToConsole: boolean = false) {
    // convert validationMessages to vee-validate errors
    const newErrors: { [property: string]: string; } = {};
    validationMessages.forEach((validationMessage: ValidationError) => {
        // for each of validation messages, add to newErrors
        newErrors[validationMessage.Property] = validationMessage.Messages[0];
    });
    errorsFromSubmit.value = newErrors;
    setErrors(newErrors);

    if (logErrorToConsole) {
        console.error(newErrors);
    }
}

const onSubmit = handleSubmit(async (values) => {
    const validationMessages: ValidationError[] = validateOriginDestination(values);
    const nowInEuropeLondon = DateTime.now().setZone("Europe/London");
    // allow 15 minutes leeway
    const past = nowInEuropeLondon.minus({ minutes: 15 }).toJSDate();
    if (store.query.outboundDate < past) {
        validationMessages.push({
            Property: "outboundDate",
            Messages: ["Outbound date must be in the future."],
        });
    }

    validationMessages.push(...validateOutboundReturnDate(values));
    const railcardValidationResult = await validateRailcardAndPassenger(values);
    validationMessages.push(...railcardValidationResult);
    validationMessages.push(...validateViaAvoid(values));

    if (validationMessages.length > 0) {
        displayValidationErrors(validationMessages, true);
    } else {
        const returnDate = store.query.journeyType === JourneyType.Return
            ? store.query.returnDate?.toISOString()
            : undefined;

        const returnTimeType = store.query.journeyType === JourneyType.Return
            ? store.query.returnTimeType
            : undefined;

        const railCardQuantity = store.query.railCard
            ? store.query.railCardQuantity
            : undefined;

        const { bookingId } = route.query;
        const query: GetJourneysRequest = {
            from: store.query.originStation?.place.placeId ?? "",
            to: store.query.destinationStation?.place.placeId ?? "",
            adults: store.query.adults,
            children: store.query.children,
            railcard: store.query.railCard?.code,
            railcardQuantity: railCardQuantity,
            journeyType: store.query.journeyType,
            outboundDate: store.query.outboundDate.toISOString(),
            outboundTimeType: store.query.outboundTimeType,
            returnDate,
            returnTimeType,
            viaAvoid: store.query.viaAvoid,
            viaAvoidStation: store.query.viaAvoidStation?.place.placeId,
        };
        emit("new-search", true);
        ticketSummaryStore.$reset();
        if (bookingId !== undefined) {
            query.bookingId = bookingId as string;
        }
        const target = router.resolve({ name: "searchresults", query }).href;
        if (isEmbedded.value) {
            window.open(target, "_top");
        } else {
            // otherwise just navigate as normal
            await router.push({ name: "searchresults", query });
        }
        openedPanel.value = JourneyPlannerMode.Compact;
    }
}, ({ errors }) => {
    // happens if invalid, and user tries to submit
    errorsFromSubmit.value = errors;
    hideSummary.value = false;
});

const forceOpenPanel = () => {
    openedPanel.value = "header";
    store.query.journeyType = JourneyType.Return;
};

defineExpose({
    syncStoreAndPinia,
    forceOpenPanel,
});

watch(mode, () => {
    if (mode.value === JourneyPlannerMode.Default) {
        openedPanel.value = "header";
        store.query.journeyType = JourneyType.Return;
    }
});
</script>

<template>
    <v-container class="pa-0 pa-md-2">
        <v-overlay v-model="isLoading"
                   contained
                   class="align-center justify-center">
            <v-progress-circular
                indeterminate
                :size="70"
                :width="7"
                color="primary">
            </v-progress-circular>
        </v-overlay>
        <v-overlay v-model="isMobile" persistent class="align-center justify-center">
            <div class="mobile-warning">
                <img src="@/assets/mtt-logo.svg" width="185" height="28" alt="MyTrainTicket">
                <div>Coming soon to mobile, please use a desktop to make bookings in the interim.</div>
            </div>
        </v-overlay>
        <v-col
            class="justify-content-center pb-0">
            <v-expansion-panels v-model="openedPanel" :readonly="!enableExpand">
                <v-expansion-panel value="header" :eager="true">

                    <v-expansion-panel-title hide-actions>

                        <v-row justify="space-between">
                            <div>
                                <font-awesome-icon icon="fa-route" class="fa-lg orange"></font-awesome-icon>
                                <span class="step-title">Plan your journey</span>
                                <div class="summary"><label>Your search: {{ selectedOutbound }} to {{ selectedReturn }}</label></div>
                            </div>

                            <div>
                                <div class="submission-error" v-if="errorsFromSubmit">
                                    <p>
                                        <font-awesome-icon icon="fa-exclamation-triangle" class="fa-lg orange"></font-awesome-icon>
                                        <span class="step-title">Something went wrong</span>
                                    </p>
                                </div>
                            </div>
                        </v-row>

                    </v-expansion-panel-title>

                    <v-expansion-panel-text>

                        <div v-show="!hideSummary">
                            <ValidationSummary :errors="errorsFromSubmit" @close-summary="closeModal" v-if="errorsFromSubmit"></ValidationSummary>
                        </div>
                        <form id="journey-planner-form" @submit.prevent="onSubmit">

                            <v-row>
                                <v-col>
                                    <OriginDestinationSelector></OriginDestinationSelector>
                                </v-col>
                            </v-row>

                            <OutboundReturnDateSelector></OutboundReturnDateSelector>
                            <PassengerSelector></PassengerSelector>

                            <div class="row mb-4">
                                <div class="p-2">
                                    <a @click="showingViaAvoidSelector = !showingViaAvoidSelector">
                                        <font-awesome-icon
                                            icon="fa-arrow-circle-down"></font-awesome-icon>
                                        Advanced Options</a>
                                </div>
                                <div class="p-2" v-if="showingViaAvoidSelector || store.hasViaAvoidStation">
                                    <div>
                                        <ViaAvoidSelector></ViaAvoidSelector>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-1">
                                </div>
                                <div class="col">
                                    <v-btn class="btn next-step-button" type="submit">
                                        <span>Get times &amp; tickets &nbsp;</span>
                                        <font-awesome-icon icon="fa-arrow-circle-right"></font-awesome-icon>
                                    </v-btn>
                                </div>
                            </div>

                        </form>

                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
        <a v-if="isEmbedded" href="https://mytrainticket.co.uk/booking-fees" class="card-details isEmbedded ml-3 mr-3">Booking and credit card fees</a>
    </v-container>
</template>

<style scoped lang="scss">

.step-title {
  text-transform: uppercase;
}

.step-detail-container {
  box-shadow: 2px 2px 4px 3px var(--vt-c-widget-box-shadow);
  padding: 25px;
}

.step-title {
  font-weight: bold;
  margin-left: 10px;
  padding-top: 15px;
}

.next-step-button {
  background-color: var(--vt-c-orange);
  color: var(--vt-c-white);
  padding: 8px;
  border-radius: 20px;
  font-size: 1.06rem;
  line-height: 1;

  span {
    text-transform: capitalize;
  }
}

.row.hidden {
  display: none;
}
a.card-details {
    display: flex;
    justify-content: center;
    background-color: var(--vt-c-white);
}
button.v-btn {
  box-shadow: none;
  width: 315px;
}

.row.step-detail-container {
  width: 720px;
  padding: 20px 50px;
  margin-left: 25%;
}

.plan-journey {
  padding-bottom: 10px;
}
.v-expansion-panel--active {
  .summary {
    display: none;
  }
}
.v-container {
  max-width: 800px;
}
.summary {
    padding-top: 15px;
    padding-bottom: 10px;
}
@media only screen and (max-width: 760px) {
    .mobile-warning {
        font-size: 17px;
        background-color: var(--vt-c-orange);
        color: var(--vt-c-white);
        padding: 25px;
        line-height: 2.5;
        border-radius: 10px;
        text-align: center;
        img {
            scale: 2.25;
        }
    }
  .v-container {
    max-width: 100%;
  }
  :deep(.v-field__input input) {
    margin-top: -3px;
  }
  .plan-journey {
    letter-spacing: 0.005rem;
    font-size: 1.07rem;
    padding-bottom: 20px;
  }

  .row.step-detail-container {
    width: 100vw;
    margin-left: 0px;
    margin-top: -60px;
    box-shadow: none;

    &.destination-section {
      background-color: var(--vt-c-orange);
      margin-bottom: -36px;
      width: 100vw;
      height: fit-content;

    .row.step-detail-container {
        width: 100vw;
        margin-left: 0px;
        margin-top: -60px;
        box-shadow: none;

        &.destination-section {
            background-color: var(--vt-c-orange);
            margin-bottom: -36px;
            width: 100vw;
            height: fit-content;
            &:has(.toggle-container) {
                padding-left: 45px;
            }

            .form-label {
                display: none;
            }
        }
      &:has(.toggle-container) {
        padding-left: 45px;
      }
    }
      .form-label {
        display: none;
      }
    }
  }
}

</style>
