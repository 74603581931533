<script setup lang="ts">
import type { ComputedRef, Ref } from "vue";
import {
    computed, onMounted, ref, toRefs, watch,
} from "vue";
import { useDateFormat } from "@vueuse/core";
import { useBookingData } from "@/stores/booking-storage";
import type {
    FaresByKeyModel,
    JourneyDestinationSummary,
    JourneySelectorViewModel,
    SelectedFare,
    TicketDetailsByKey,
    TicketRestrictionSummary,
} from "@/models/JourneySelectorViewModel";
import { gqlBookingService } from "@/services/GQLService";
import { useTicketSummaryStore } from "@/stores/ticket-summary-store";
import { useJourneyStore } from "@/stores/journey-store";
import { handleError } from "@/services/AlertService";
import JourneySummary from "@/components/3-Basket/JourneySummary.vue";
import { getRailcardSelection } from "@/utilities/GetRailcardFromFare";
import { JourneyViewType } from "@/models/basket/JourneyViewType";
import JourneyPackages from "@/components/3-Basket/JourneyPackages.vue";
import PriceBreakdown from "@/components/3-Basket/PriceBreakdown.vue";
import TicketDetails from "@/components/3-Basket/TicketDetails.vue";
import { useRoute, useRouter } from "vue-router";
import type { IPriceBreakdown } from "@/models/graphql/PriceBreakdownResponse";
import { Reservability } from "@/models/basket/Reservability";
import { TravelPolicyUtilities } from "@/utilities/TravelPolicyUtilities";
import { JourneyUtilities } from "@/utilities/JourneyUtilities";
import { TransportModes } from "@/models/basket/TransportModes";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = withDefaults(defineProps<{
  searchResults: JourneySelectorViewModel,
  selectedOutbound: SelectedFare | null,
  selectedReturn: SelectedFare | null,
}>(), {
    selectedOutbound: null,
    selectedReturn: null,
});

const { searchResults } = toRefs(props);
const { selectedOutbound } = toRefs(props);
const { selectedReturn } = toRefs(props);

const dialog: Ref<boolean> = ref(false);
const ticketSummarystore = useTicketSummaryStore();
const journeyStore = useJourneyStore();
const mobileView = window.location.pathname.includes("your-selection");
const js: Ref = ref(null);
const jp: Ref = ref(null);
const td: Ref = ref(null);
const pb: Ref = ref(null);
const route = useRoute();
const router = useRouter();
const selectedFare: Ref<string> = ref("");
const isLoading: Ref<boolean> = ref(false);
const ticketDetails: Ref<TicketDetailsByKey | null> = ref(null);
const fareDetails: Ref<FaresByKeyModel | null> = ref(null);
const restrictionDetails: Ref<TicketRestrictionSummary | null> = ref(null);
const outboundSummary: Ref<JourneyDestinationSummary | null> = ref(null);
const inboundSummary: Ref<JourneyDestinationSummary | null> = ref(null);
const journeyTypeSelection: Ref<JourneyViewType> = ref(JourneyViewType.Outbound);
const isOutFirstClass: Ref<boolean | undefined> = ref(false);
const isRetFirstClass: Ref<boolean | undefined> = ref(false);
const availableOutboundFC: Ref<number> = ref(0);
const availableInboundFC: Ref<number> = ref(0);
const isReturnPackage: Ref<boolean> = ref(false);
const returnPackageData: Ref<SelectedFare | null> = ref(null);
const packageReset: Ref<boolean> = ref(false);
const priceBreakdown: Ref<IPriceBreakdown | null> = ref(null);
const existingBooking: Ref<string | null> = ref(null);
const existingTrips: Ref<any> = ref(null);
const willBookingFeeChangeDueToAddition: Ref<boolean> = ref(false);
const bookingStore = useBookingData();
const isOpenReturn: Ref<boolean> = ref(false);

function checkFirstClassLegs(journeyType: JourneyViewType): void {
    let num = 0;
    if (journeyType === JourneyViewType.Inbound) {
        ticketSummarystore.inboundDetails.value?.operators.filter((x) => !x.firstClass).forEach(() => num++);
        availableInboundFC.value = num;
    } else {
        ticketSummarystore.outboundDetails.value?.operators.filter((x) => !x.firstClass).forEach(() => num++);
        availableOutboundFC.value = num;
    }
}

function showDialog() {
    dialog.value = true;
}
defineExpose({
    showDialog,
    dialog, // for testing only
});

onMounted(() => {
    outboundSummary.value = ticketSummarystore.selectedOutbound.value;
    inboundSummary.value = ticketSummarystore.selectedInbound.value;
});

// must have a selectedOutbound and selectedReturn
const canChooseReturnPackages = computed(() => selectedOutbound.value?.isOpenReturn || (selectedOutbound.value && selectedReturn.value));
const localTesting = () => false && window.location.hostname === "localhost";

// TODO: why is this function called getTotalForFareKeys? It doesn't return anything
// Could this simply be a computed property instead?
/*  const getTotalForFareKeys = (keys: number[]) => {
    // if we have a list of fare keys
    // *and* they all relate to this search (future improvement: they need clearing/re-setting properly after a new search)
    if (searchResults.value && keys && Object.keys(searchResults.value.faresByFareKey).every((key) => searchResults.value.faresByFareKey[key])) {
        totalCost.value = keys.map((fareKey) => searchResults.value.faresByFareKey[fareKey].price).reduce((partialSum, a) => partialSum + a, 0);
    }
};  */

const getFareKeysToUse = () => {
    if (isReturnPackage.value) {
        return [Number(returnPackageData.value!.fareKey)];
    } if (inboundSummary.value !== null) {
        const outFareKeyToUse = Number(selectedOutbound.value?.fareKey);
        const retFareKeyToUse = Number(selectedReturn.value?.fareKey);
        return [outFareKeyToUse, retFareKeyToUse];
    }
    const outFareKeyToUse = Number(selectedOutbound.value?.fareKey);
    return [outFareKeyToUse];
};

watch(ticketSummarystore.selectedOutbound, () => {
    // @ts-ignore
    outboundSummary.value = ticketSummarystore.selectedOutbound.value!;
    isOpenReturn.value = selectedOutbound.value?.isOpenReturn!;
    isOutFirstClass.value = !!ticketSummarystore.selectedOutboundFareType.value?.includes("1st");
    if (isOutFirstClass.value) { checkFirstClassLegs(JourneyViewType.Outbound); }
    // Clear return package data when a new journey is selected
    if (isReturnPackage.value) {
        isReturnPackage.value = false;
        selectedReturn.value = null;
        selectedOutbound.value = null;
        packageReset.value = true;
    }
});

watch(ticketSummarystore.selectedInbound, () => {
    // @ts-ignore
    inboundSummary.value = ticketSummarystore.selectedInbound.value!;
    isRetFirstClass.value = !!ticketSummarystore.selectedReturnFareType.value?.includes("1st");
    if (isRetFirstClass.value) { checkFirstClassLegs(JourneyViewType.Inbound); }
    // Clear return package data when a new journey is selected
    if (isReturnPackage.value) {
        isReturnPackage.value = false;
        selectedOutbound.value = null;
        selectedReturn.value = null;
        packageReset.value = true;
    }
});

function formatPrice(price: number) {
    return new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(price);
}

async function getInboundPriceBreakdown() {
    existingBooking.value = bookingStore.checkExistingBooking();
    const outboundKey = selectedOutbound!.value!.journeyKey;
    const inboundKey = selectedReturn.value?.journeyKey;
    const fareKeys: string[] = isReturnPackage.value ? [returnPackageData.value!.fareKey!] : [selectedOutbound!.value!.fareKey!, selectedReturn!.value!.fareKey!];
    try {
        priceBreakdown.value = await gqlBookingService.computePriceBreakdown(searchResults.value.searchId, outboundKey, existingBooking.value, fareKeys, inboundKey);
        isLoading.value = false;
    } catch (error) {
        isLoading.value = false;
        handleError(error);
    }
}

async function getOutboundPriceBreakdown() {
    existingBooking.value = bookingStore.checkExistingBooking();
    const outboundKey = selectedOutbound!.value!.journeyKey;
    const fareKeys = isOpenReturn.value ? [returnPackageData.value!.fareKey!] : [selectedOutbound!.value!.fareKey!];
    if (selectedReturn.value === null) {
        try {
            priceBreakdown.value = await gqlBookingService.computePriceBreakdown(searchResults.value.searchId, outboundKey, existingBooking.value, fareKeys);
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            handleError(error);
        }
    } else {
        getInboundPriceBreakdown();
    }
}

function calculatePrice() {
    let total: string = "";
    const bookingFee = bookingStore.getExistingBookingFee();
    if (selectedReturn.value !== null && bookingFee! < 1.50) {
        willBookingFeeChangeDueToAddition.value = true;
        const priceOfTickets = isReturnPackage.value ? priceBreakdown.value?.tripAmount! : selectedOutbound.value!.selectedFare!.price + selectedReturn.value.selectedFare!.price;
        const additionalFee = priceBreakdown.value!.bookingFee / 2;
        total = formatPrice(priceOfTickets + additionalFee);
    } else if (isReturnPackage.value && bookingFee! === 1.50) {
        total = formatPrice(priceBreakdown.value?.tripAmount!);
    } else if (selectedReturn.value === null) {
        total = selectedOutbound.value!.selectedFare!.priceFormatted;
    } else {
        total = formatPrice(selectedOutbound.value!.selectedFare!.price + selectedReturn.value.selectedFare!.price);
    }
    return total;
}

async function showTicketDetails(journeyType: JourneyViewType) {
    const journeySelection = journeyType;
    if (journeySelection === JourneyViewType.Outbound && !isReturnPackage.value) {
        ticketDetails.value = selectedOutbound.value!.ticketDetails!;
        fareDetails.value = selectedOutbound.value!.selectedFare!;
        journeyTypeSelection.value = JourneyViewType.Outbound;
        restrictionDetails.value = selectedOutbound.value?.ticketRestrictions!;
    } else {
        ticketDetails.value = selectedReturn.value!.ticketDetails!;
        fareDetails.value = selectedReturn.value!.selectedFare!;
        journeyTypeSelection.value = JourneyViewType.Inbound;
        restrictionDetails.value = selectedReturn.value?.ticketRestrictions!;
    }
    if (isReturnPackage.value) {
        ticketDetails.value = returnPackageData.value?.ticketDetails!;
        fareDetails.value = returnPackageData.value?.selectedFare!;
        restrictionDetails.value = returnPackageData.value?.ticketRestrictions!;
    }
    td.value.showDialog();
}

function formatDate(date: string | undefined) {
    return useDateFormat(date, "HH:mm DD MMMM YYYY").value;
}

function showOtherReturnFaresModal() {
    jp.value.showDialog();
}

function togglePriceBreakdown() {
    pb.value.showDialog();
}

function setPackageSelection(packageData: SelectedFare) {
    // Find search id's required here
    returnPackageData.value = packageData;
    isReturnPackage.value = true;
    packageReset.value = false;
}

function resetPackageSelection() {
    returnPackageData.value = null;
    isReturnPackage.value = false;
    packageReset.value = true;
}

watch(selectedOutbound, () => {
    resetPackageSelection();
    getOutboundPriceBreakdown();
});

watch(returnPackageData, () => {
    if (isOpenReturn.value) {
        getOutboundPriceBreakdown();
    } else {
        getInboundPriceBreakdown();
    }
});

watch(selectedReturn, () => {
    resetPackageSelection();
    if (selectedReturn.value !== null) {
        getInboundPriceBreakdown();
    } else {
        inboundSummary.value = null;
    }
});

watch(existingBooking, () => {
    existingTrips.value = bookingStore.bookedTrips();
});

async function showJourneySummary(journeyType: number) {
    const journeySelection = journeyType;
    // Temporary- Currently the backend always seems to set the faretype to 'Standard'
    if (journeySelection === 1) {
        selectedFare.value = ticketSummarystore.selectedOutboundJourneyKey.value!;
        journeyTypeSelection.value = JourneyViewType.Outbound;
    } else {
        selectedFare.value = ticketSummarystore.selectedInboundJourneyKey.value!;
        journeyTypeSelection.value = JourneyViewType.Inbound;
    }
    js.value.showDialog();
}

function validateJourneyTimes() {
    const outbound = selectedOutbound.value !== null ? new Date(selectedOutbound.value!.journeyDetails!.departing) : null;
    const now = new Date();
    return outbound !== null ? outbound > now : true;
}

const railCards = computed(() => getRailcardSelection(selectedOutbound.value!, selectedReturn?.value));

const hasAnyMandatoryReservations: ComputedRef<boolean> = computed(() => {
    const fareKeys = getFareKeysToUse();
    const anyAdvances = fareKeys!.map((fareKey) => searchResults.value.faresByFareKey[fareKey].isAdvance).some((a) => a);
    const selectedOutboundJk = selectedOutbound.value?.journeyKey;
    const selectedReturnJk = selectedReturn.value?.journeyKey;
    const anyMandatoryReservations = searchResults.value.allJourneys!.some((journey) => {
        if (journey.key === selectedOutboundJk || journey.key === selectedReturnJk) {
            return journey.legs.some((leg) => leg.seatReservationLevel === Reservability.Required);
        }
        return false;
    });
    return anyAdvances || anyMandatoryReservations;
});

const travelPolicyViolations = computed(() => {
    const fareKeys = getFareKeysToUse();
    if (fareKeys.length === 0) {
        return [];
    }
    const outboundKey = selectedOutbound.value?.journeyKey;
    if (!outboundKey) {
        return [];
    }
    const inboundKey = selectedReturn.value?.journeyKey;
    return TravelPolicyUtilities.getViolationsForJourneyAndFares(outboundKey!, inboundKey ?? null, fareKeys.map((fk) => fk.toString()), searchResults.value);
});

const validSelection = computed(() => validateJourneyTimes());

function proceed() {
    const { bookingId } = route.query;
    const fareKeys = getFareKeysToUse();
    if (bookingId) {
        router.push({
            path: "/seat-reservation/",
            query: {
                bookingId,
                fareKeys,
                searchQuery: searchResults.value.searchId,
                outKey: selectedOutbound.value?.journeyKey,
                inKey: selectedReturn.value?.journeyKey,
                searchId: searchResults.value.searchId,
                hasAnyMandatoryReservations: hasAnyMandatoryReservations.value.toString(),
            },
        });
    } else {
        router.push({
            path: "/seat-reservation/",
            query: {
                fareKeys,
                searchQuery: searchResults.value.searchId,
                outKey: selectedOutbound.value?.journeyKey,
                inKey: selectedReturn.value?.journeyKey,
                searchId: searchResults.value.searchId,
                hasAnyMandatoryReservations: hasAnyMandatoryReservations.value.toString(),
            },
        });
    }
}
</script>

<template>
    <div :class="[mobileView ? 'mobile-view' : '', 'customer-selection']">
        <v-overlay v-model="isLoading"
                   contained
                   class="align-center justify-center">
            <v-progress-circular
                indeterminate
                :size="70"
                :width="7"
                color="primary">
            </v-progress-circular>
        </v-overlay>
        <v-card>
            <v-card-title class="text-center">
                Your Selection
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="debugging">
                <div class="text-center" v-if="localTesting()">
                    <dl>
                        <dt>Selected outboundID & journey key</dt>
                        <dd>{{ selectedOutbound?.journeyKey }}</dd>
                        <dd>{{ selectedOutbound?.fareKey }}</dd>
                        <dt>Selected returnID & journey key</dt>
                        <dd>Return JK {{ selectedReturn?.journeyKey }}</dd>
                        <dd>Return SFK {{ selectedReturn?.fareKey }}</dd>
                        <dd>Return SearchId {{ searchResults?.searchId }}</dd>
                    </dl>
                </div>
            </v-card-text>
            <v-alert :rounded="false" v-if="selectedOutbound?.journeyKey && JourneyUtilities.involvesTransportMode(selectedOutbound?.journeyKey!, TransportModes.Bus, searchResults)" color="var(--vt-c-warning)">
                <div class="text-center">
                    <h3 class="font-weight-bold"><font-awesome-icon icon="fa-bus" ></font-awesome-icon> Outward journey has at least one bus</h3>
                    Please review the itinerary before proceeding to ensure you are happy with the journey.
                </div>
            </v-alert>
            <v-alert :rounded="false" v-if="selectedReturn?.journeyKey && JourneyUtilities.involvesTransportMode(selectedReturn?.journeyKey!, TransportModes.Bus, searchResults)" color="var(--vt-c-warning)">
                <div class="text-center">
                    <h3 class="font-weight-bold"><font-awesome-icon icon="fa-bus" ></font-awesome-icon> Return journey has at least one bus</h3>
                    Please review the itinerary before proceeding to ensure you are happy with the journey.
                </div>
            </v-alert>
            <v-alert :rounded="false" v-if="travelPolicyViolations.length > 0" color="var(--vt-c-warning)">
                <div class=" text-center">
                    <h3 class="font-weight-bold">Selection does not meet travel policy</h3>
                    <div v-for="(violation, i) in travelPolicyViolations" :key="i">
                        <p>{{ violation }}</p>
                    </div>
                </div>
            </v-alert>
            <v-card-text v-if="!ticketSummarystore.cheapestTicket" class="cheaper-warning">
                <div class="bg-white rounded-lg pa-5 mb-2 text-center">
                    <h3 class="font-weight-bold">Cheaper Tickets</h3>
                    There are cheaper alternatives for your selected journey
                </div>
            </v-card-text>
            <v-card-text class="text-left" >
                <div class="bg-white pa-5 mb-2">
                    <div class="row title">
                        <h3 class="font-weight-bold">Journeys</h3>
                        <div class="outbound-summary">
                            <p>{{ `Out: ${formatDate(ticketSummarystore.outboundDetails.value?.departing)}` }}</p>
                            <a @click="showJourneySummary(1)">
                                <span v-if="ticketSummarystore.selectedOutboundChanges.value === 0">Direct</span>
                                <span v-else> {{ ticketSummarystore.selectedOutboundChanges}} changes</span>
                            </a>
                            - {{ outboundSummary?.fromName }} to {{ outboundSummary?.toName }}
                        </div>
                        <div v-if="inboundSummary !== null" class="inbound-summary">
                            <p>{{ `Return: ${formatDate(ticketSummarystore.inboundDetails.value?.departing)}` }}</p>
                            <a @click="showJourneySummary(2)">
                                <a v-if="ticketSummarystore.selectedInboundChanges.value === 0">Direct</a>
                                <a v-else> {{ ticketSummarystore.selectedInboundChanges}} changes</a>
                            </a>
                            - {{ inboundSummary?.fromName }} to {{ inboundSummary?.toName }}
                        </div>
                        <h3 class="font-weight-bold pt-10">Tickets</h3>
                        <div v-if="!isReturnPackage"  >
                            <div class="ticket-details">
                                <div class="outbound-details">
                                    <b>Out:</b> <a @click="showTicketDetails(JourneyViewType.Outbound)"> <span v-if="selectedOutbound?.selectedFare?.crossLondon === true">✠ </span>{{ ticketSummarystore.selectedOutboundFareType.value }} </a>
                                    <button v-if="isOutFirstClass && availableOutboundFC > 0"  class="first-class-warning pt-2 pb-2" @click="showJourneySummary(1)">
                                        <font-awesome-icon icon="fa-exclamation-triangle"></font-awesome-icon>
                                        {{ `Some legs (${availableOutboundFC}/${ticketSummarystore.selectedOutboundChanges.value + 1}) of this journey are not in first class.` }}
                                    </button>
                                </div>
                            </div>
                            <p v-if="journeyStore.query.adults > 0"> {{ journeyStore.query.adults }} Adults</p>
                            <p v-if="journeyStore.query.children > 0"> {{ journeyStore.query.children }} Child</p>
                            {{ formatPrice(ticketSummarystore.selectedOutboundFare.value) }}
                            <div v-if="inboundSummary !== null" class="pt-5">
                                <!-- This was the only field that I could find to differentiate between the journey types
                                I cannot locate anything to identify whether a ticket is off peak, anytime single etc -->
                                <b>Return:</b> <a  @click="showTicketDetails(JourneyViewType.Inbound)"><span v-if="selectedReturn?.selectedFare?.crossLondon === true">✠ </span> {{ ticketSummarystore.selectedReturnFareType.value }} </a>
                                <button v-if="isRetFirstClass && availableInboundFC > 0" class="first-class-warning pt-2 pb-2" @click="showJourneySummary(2)">
                                    <font-awesome-icon icon="fa-exclamation-triangle"></font-awesome-icon>
                                    {{ `Some legs (${availableInboundFC}/${ticketSummarystore.selectedInboundChanges.value + 1}) of this journey are not in first class.` }}
                                </button>
                                <div class="pb-5" @click="dialog = false">
                                    <p v-if="journeyStore.query.adults > 0"> {{ journeyStore.query.adults }} Adults</p>
                                    <p v-if="journeyStore.query.children > 0"> {{ journeyStore.query.children }} Child</p>
                                    {{ formatPrice(ticketSummarystore.selectedReturnFare.value) }}
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <a @click="showTicketDetails(JourneyViewType.Inbound)">{{ returnPackageData?.ticketType }}</a>
                            {{ returnPackageData?.ticketPrice }}
                            <p v-if="journeyStore.query.adults > 0"> {{ journeyStore.query.adults }}
                                <span v-if="journeyStore.query.adults > 1">Adults</span> <span v-else>Adult</span>
                            </p>
                            <p v-if="journeyStore.query.children > 0"> {{ journeyStore.query.children }}
                                <span v-if="journeyStore.query.children > 1">Children</span> <span v-else>Child</span>
                            </p>
                        </div>
                        <div v-if="railCards !== null" class="pb-2 pt-2">
                            <div v-for="(card, i) in railCards" :key="i">
                                <font-awesome-icon class="pr-3" icon="fa-id-card"></font-awesome-icon>{{  card.name  }} savings applied
                            </div>
                        </div>
                        <v-divider></v-divider>
                        <a v-if="inboundSummary !== null || isOpenReturn" @click="showOtherReturnFaresModal()"> View other return fares for your journey</a>
                        <div class="ticket-fee text-center pt-5" v-if="searchResults">
                            <div class="has-pricing-breakdown" v-if="priceBreakdown">
                                <h3 class="font-weight-bold mt-5">Total</h3>
                                <div v-if="existingBooking === null" tabindex="0" data-toggle="tooltip" title="Includes booking fees, where applicable" class="total-price">
                                    <h2 class="pb-8">{{ formatPrice(priceBreakdown?.totalPaymentAmount!) }}</h2>
                                </div>
                                <div v-else tabindex="0" data-toggle="tooltip" title="Includes booking fees, where applicable" class="total-price">
                                    <h2 class="pb-8">{{ calculatePrice() }}</h2>
                                </div>
                                <a class="nav-link">
                                    <v-btn class="primary-button mb-5" :disabled="!validSelection || travelPolicyViolations.length > 0" @click="proceed()">Proceed</v-btn>
                                </a>
                                <div v-if="!validSelection" class="text-error pb-3 font-weight-bold">
                                    Departure date cannot be in the past
                                </div>
                                <div>
                                    <span v-if="existingBooking === null" class="text-subtitle-2 font-weight-bold pb-1">
                                        <font-awesome-icon icon="fa-circle-info"></font-awesome-icon>
                                        The total includes the ticket price plus a small fee of {{ formatPrice(priceBreakdown?.bookingFee!) }}.
                                    </span>
                                    <span v-else-if="existingBooking !== null && willBookingFeeChangeDueToAddition">
                                        <font-awesome-icon icon="fa-circle-info"></font-awesome-icon>
                                        If you add this journey to your basket, the booking fee will change to £1.50. The total above includes the differnce in fee.
                                    </span>
                                    <span v-else>
                                        <font-awesome-icon icon="fa-gift"></font-awesome-icon>
                                        <b> No additional booking fee.</b> We won't charge an additional fee for this journey, as long as you purchase it with the {{ existingTrips.length }} other journey {{ existingTrips.length === 1 ? "that's" : "that're" }} in your basket.
                                    </span>
                                    <div>
                                        <a @click="togglePriceBreakdown()">Price Breakdown</a>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <v-progress-circular indeterminate size="15" class="mr-1"></v-progress-circular> One moment&hellip;
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <JourneySummary ref="js" v-if="selectedReturn?.journeyDetails || selectedOutbound?.journeyDetails" :inboundJourney="selectedReturn?.journeyDetails!" :outboundJourney="selectedOutbound?.journeyDetails!" :journey-type="journeyTypeSelection" :first-class-fare="false"></JourneySummary>
        <JourneyPackages v-if="canChooseReturnPackages" ref="jp" :searchResults="searchResults" :selectedOutbound="selectedOutbound" :isOpenReturn="isOpenReturn" :selectedReturn="selectedReturn" :selected-fare="selectedFare" :packageDeselected="packageReset" @returnPackageSelected="setPackageSelection($event)"></JourneyPackages>
        <TicketDetails ref="td" :ticket-detail="ticketDetails!" :journey-fare="fareDetails" :fare-type="journeyTypeSelection" :restrictionDetails="restrictionDetails"></TicketDetails>
        <PriceBreakdown ref="pb" :price-breakdown="priceBreakdown"></PriceBreakdown>
    </div>
</template>

<style scoped lang="scss">
.card {
    width: 800px;
}

.v-card {
    box-shadow: none;
    border: 3px solid var(--vt-light-gray);
    .v-card-title {
        border-bottom: 3px solid var(--vt-light-gray);
    }
    .v-card-text {
        height: fit-content;
        .cheaper-warning div {
            border: 3px solid var(--vt-c-orange);
        }
    }
    .passenger-details {
        font-size: 1.2rem;
        &.text-center {
            display: block;
            justify-content: center;
            padding-bottom: 20px;
            text-align: center;
            line-height: 1.5;
        }
        div {
            padding-left: 10px;
        }
    }
    .ticket-price {
        font-size: 22px;
        font-weight: 700;
    }
    .ticket-price:first-letter {
        font-size: 20px;
        font-weight: 500;
    }
    span.fare-price {
        font-weight: bold;
        font-size: 1.2rem;
    }
}
.v-dialog > .v-overlay__content > .v-card > .v-card-text {
    font-size: 1rem;
}
.row.booking-fee {
    display: inline-flex;
    svg {
        padding-right: 10px;
        padding-right: 10px;
        scale: 1.5;
        padding-top: 2px;
        color: var(--vt-c-orange);
    }
}
.first-class-warning {
    color: var(--vt-c-red);
    cursor: pointer;
    text-align: left;
    text-decoration: underline;
}
.primary-button {
    box-shadow: none;
    margin-top: -20px;
    width: 40%;
}
a {
    text-decoration: underline;
}
.customer-selection {
    width: 410px;
    display: flex;
    padding-left: 1.25rem;
    margin-top: 2.5rem;
}

@media only screen and (max-width: 760px) {
    .customer-selection {
       display: none;
       &.mobile-view {
            display: block;
            margin-top: 0rem;
       }
    }
}

</style>
