<script setup lang="ts">
import PaymentSummary from "@/components/4-Checkout/PaymentSummary.vue";
import { useRouter } from "vue-router";
import { retailInstanceTypeSymbol } from "@/models/Symbols";
import type { RetailInstanceTypeService } from "@/services/RetailInstanceTypeService";
import { inject } from "vue";

const instanceTypeSvc = inject(retailInstanceTypeSymbol) as RetailInstanceTypeService;
const router = useRouter();
const addAnotherJourney = () => {
    router.push({ name: "home" });
};
</script>
<template>
    <PaymentSummary></PaymentSummary>
    <div class="pa-3">
        <div v-if="instanceTypeSvc.isConsumer()" @click="addAnotherJourney" class="pt-2 pb-3">
            <a>Add another journey to this booking?</a>
        </div>
        The National Rail Conditions of Travel (NRCoT) will govern your journey.
        By purchasing, you agree to be bound by this agreement. For more information
        on your rights and responsibilities, you can
        <a href="https://www.nationalrail.co.uk/travel-information/your-rights-and-obligations-as-a-passenger/"  target="_blank">read the NRCoT in full online.</a>
    </div>
</template>
