import type { JourneySelectorViewModel } from "@/models/JourneySelectorViewModel";
import type { TransportModes } from "@/models/basket/TransportModes";
import { getJourneyFromKey } from "@/utilities/GetJourneyFromKey";

export class JourneyUtilities {
    static involvesTransportMode(journeyKey: string, mode: TransportModes, journeySelectorViewModel: JourneySelectorViewModel): boolean {
        const journey = getJourneyFromKey(journeyKey, journeySelectorViewModel.allJourneys!);
        return journey.legs.some((l) => l.mode === mode);
    }
}
