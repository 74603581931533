<script setup lang="ts">
import { useField, useForm } from "vee-validate";
import { handleError } from "@/services/AlertService";

const emit = defineEmits(["guestCheckoutData", "isLoading", "guestUserData"]);
const { handleSubmit } = useForm();
const firstNameField = useField<String>("userFirstName", "required", {
    label: "First name",
});
const userFirstName = firstNameField.value;

const surnameField = useField<String>("userSurname", "required", {
    label: "Surname",
});
const userSurname = surnameField.value;

const emailField = useField<String>("userEmail", "required|email", {
    label: "Email address",
});
const userEmail = emailField.value;

const submit = handleSubmit(async () => {
    try {
        emit("guestUserData", { firstName: userFirstName.value, surname: userSurname.value, userEmail: userEmail.value });
    } catch (error) {
        handleError(error);
    }
});
</script>

<template>
    <div>
        <v-card class="pa-0 pa-md-2 pb-5 guest-checkout">
            <v-card-title>Guest Checkout</v-card-title>
            <v-card-text class="checkout-section">
                <v-form>
                    <v-text-field
                        label="First name"
                        v-model="userFirstName"
                        :error-messages="firstNameField.errorMessage.value"
                        required></v-text-field>
                    <v-text-field
                        label="Surname"
                        v-model="userSurname"
                        :error-messages="surnameField.errorMessage.value"
                        required></v-text-field>
                    <v-text-field
                        label="Email"
                        v-model="userEmail"
                        :error-messages="emailField.errorMessage.value"
                        required></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn class="primary-button" @click="submit">Checkout</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<style scoped lang="scss">
:deep(input[type=text]) {
    padding: 30px 0px 5px 15px !important;
}
:deep(.v-field__field) {
    background-color: var(--vt-c-white);
}
</style>
