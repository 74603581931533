<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import {
    onMounted, ref, defineEmits, inject,
} from "vue";
import { retailInstanceTypeSymbol } from "@/models/Symbols";
import type { RetailInstanceTypeService } from "@/services/RetailInstanceTypeService";
import type { Ref } from "vue";
import { fetchAuthSession } from "aws-amplify/auth";
import type { FulfilmentRequestResponse } from "@/models/checkout/FulfilmentRequestModel";
import StripeWidget from "@/components/4-Checkout/StripeWidget.vue";
import CheckoutLhs from "@/components/4-Checkout/CheckoutLhs.vue";
import type { CompanyDetails } from "@/models/graphql/CompanyDetailsTypes";
import { gqlCompanyService } from "@/api/GQLCorporateClient";
import type { CorporateUserDetails } from "@/models/graphql/CorporateUserTypes";
import type { SubmitPaymentEventData } from "@/models/checkout/SubmitPaymentEventData";
import type { ManagementInformationQuestionsResponse } from "@/models/graphql/ManagementInformationQuestionsTypes";
import MiQuestions from "@/components/4-Checkout/MiQuestions.vue";
import PaymentConfirmation from "./PaymentConfirmation.vue";

const route = useRoute();
const router = useRouter();
const bookingData: Ref<FulfilmentRequestResponse | null> = ref(null);
const userEmail: Ref<string> = ref("");
const paymentConfirmed: Ref<boolean> = ref(false);
const isLoading: Ref<boolean> = ref(false);

const bookingId = route.query.bookingId as string;

const companyDetails: Ref<CompanyDetails|null> = ref(null);
const userDetails: Ref<CorporateUserDetails|null> = ref(null);
const miQuestions: Ref<ManagementInformationQuestionsResponse|null> = ref(null);

const miQuestionsComponentRef: Ref = ref(null);
const instanceTypeSvc = inject(retailInstanceTypeSymbol) as RetailInstanceTypeService;

const emit = defineEmits(["payment-successful"]);

async function submitPayment(eventData: SubmitPaymentEventData) {
    if (miQuestions.value && miQuestionsComponentRef.value) {
        const answers = await miQuestionsComponentRef.value.getAnswers();
        if (!answers) {
            // eslint-disable-next-line no-alert
            window.alert("Please answer all MI questions");
            return;
        }
        // emit it with the answers added into the object
        emit("payment-successful", { ...eventData, miQuestionAnswers: answers });
    } else {
        emit("payment-successful", eventData);
    }
}

function onAccountPaymentSubmit() {
    const eventData = {
        paymentIntentId: null,
        onAccount: true,
    } as SubmitPaymentEventData;
    submitPayment(eventData);
}

onMounted(async () => {
    await router.isReady();
    isLoading.value = true;
    const session = await fetchAuthSession();
    if (session.tokens !== undefined) { // if we're logged in
        userEmail.value = session.tokens.signInDetails!.loginId!;
        // and if we're a corp widget...
        if (instanceTypeSvc.isCorporate()) {
            companyDetails.value = await gqlCompanyService.getCompanyDetails();
            userDetails.value = await gqlCompanyService.getCorporateUserDetails();
            miQuestions.value = await gqlCompanyService.getManagementInformationQuestions();
        }
    }
    isLoading.value = false;
});
const panel = ref([0]);
</script>

<template>
    <div>
        <v-overlay v-model="isLoading"
                   contained
                   class="align-center justify-center">
            <v-progress-circular
                :indeterminate="true"
                :size="70"
                :width="7"
                color="primary">
            </v-progress-circular>
        </v-overlay>
        <v-container>
            <v-col class="col-sm authenticated-checkout">
                <CheckoutLhs></CheckoutLhs>
            </v-col>
            <v-col class="col-sm auth-user-payment">
                <div class="pb-5">&nbsp;</div>
                <div v-if="miQuestions && (miQuestions.dropdownQuestions || miQuestions.freeTextQuestions)">
                    <h2 class="pb-1">Management questions</h2>
                    <MiQuestions :questions="miQuestions" ref="miQuestionsComponentRef"></MiQuestions>
                    <h2 class="pb-5">Payment</h2>
                </div>

                <v-expansion-panels v-model="panel" elevation="1" eager variant="accordion" v-if="companyDetails?.hasOnAccountPayment">
                    <v-expansion-panel key="onAccount" collapse-icon="mdi-radiobox-marked" expand-icon="mdi-radiobox-blank">
                        <v-expansion-panel-title>On-account payment</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="pa-2">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <p class="mt-2"><font-awesome-icon icon="fa-university"></font-awesome-icon> {{userDetails?.costCenter}}</p>
                                <p>{{userDetails?.department}}</p>
                                <v-btn class="mt-4 primary-button" @click="onAccountPaymentSubmit">
                                    <font-awesome-icon icon="fa-lock fa-solid" class="mr-2"></font-awesome-icon>
                                    Pay on Account <v-progress-circular indeterminate size="15" class="ml-1" v-if="false"></v-progress-circular>
                                </v-btn>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel key="cardPayment" collapse-icon="mdi-radiobox-marked" expand-icon="mdi-radiobox-blank">
                        <v-expansion-panel-title>Card Payment</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="pa-2">
                                <StripeWidget :booking-id="bookingId" @payment-successful="submitPayment"></StripeWidget>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
                <StripeWidget v-else :booking-id="bookingId" @payment-successful="submitPayment"></StripeWidget>

            </v-col>
        </v-container>
        <div v-if="paymentConfirmed">
            <PaymentConfirmation
                :email="userEmail"
                :bookingData="bookingData!"></PaymentConfirmation>
        </div>
    </div>
</template>

<style scoped lang="scss">
.v-container {
    display: flex;
    max-width: 1200px;
}
</style>
