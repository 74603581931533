import { type IFulfilmentClient, FulfilmentClient } from "@/api/FulfilmentClient";
import ApiClient from "@/api/ApiClient";
import type { IGuestCheckoutRequest, IAuthenticatedCheckoutRequest } from "@/models/checkout/GuestCheckoutRequest";
import type { IBookingData } from "@/models/checkout/FulfilmentRequestModel";

export class FulfilmentService {
    fulfilmentClient: IFulfilmentClient;

    constructor(fulfilmentClient: IFulfilmentClient) {
        this.fulfilmentClient = fulfilmentClient;
    }

    async completeGuestBooking(bookingId: string, request: IGuestCheckoutRequest): Promise<IBookingData> {
        return this.fulfilmentClient.guestCheckout(bookingId, request);
    }

    async authenticatedPayment(bookingId: string, request: IAuthenticatedCheckoutRequest): Promise<IBookingData> {
        return this.fulfilmentClient.authenticatedPayment(bookingId, request);
    }

    async authenticatedOnAccountPayment(bookingId: string, request: IAuthenticatedCheckoutRequest): Promise<IBookingData> {
        return this.fulfilmentClient.authenticatedOnAccountPayment(bookingId, request);
    }
}

const fulfilmentApiClient = new FulfilmentClient(new ApiClient());
export const fulfilmentService = new FulfilmentService(fulfilmentApiClient);
