import type { IGQLCorporateClient } from "@/api/GQLCorporateClient";
import type { CompanyDetails } from "@/models/graphql/CompanyDetailsTypes";
import type { CorporateUserDetails } from "@/models/graphql/CorporateUserTypes";
import type { ManagementInformationQuestionsResponse } from "@/models/graphql/ManagementInformationQuestionsTypes";

export class GQLCorporateService {
    gqlCorporateClient: IGQLCorporateClient;

    constructor(gqlCompanyClient: IGQLCorporateClient) {
        this.gqlCorporateClient = gqlCompanyClient;
    }

    async getCompanyDetails(): Promise<CompanyDetails> {
        return this.gqlCorporateClient.getCompanyDetails();
    }

    async getCorporateUserDetails(): Promise<CorporateUserDetails> {
        return this.gqlCorporateClient.getCorporateUserDetails();
    }

    async getManagementInformationQuestions(): Promise<ManagementInformationQuestionsResponse> {
        return this.gqlCorporateClient.getManagementInformationQuestions();
    }
}
