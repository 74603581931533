import type { JourneySelectorViewModel } from "@/models/JourneySelectorViewModel";

export class TravelPolicyUtilities {
    static getViolationsForJourneyAndFares(outboundKey: string, inboundKey: string | null, faresArray: string[], results: JourneySelectorViewModel) {
        const matchingPolicyInformationEntries = results.travelPolicyInformation.filter(
            (policyInformation) => policyInformation.journeyOutKey === outboundKey
                && (!inboundKey || policyInformation.journeyInKey === inboundKey),
        );

        // if we've not got any, return empty array
        if (matchingPolicyInformationEntries.length === 0) {
            return [];
        }

        const violationKeys: string[] = [];
        // are there relevant fares combinations?
        matchingPolicyInformationEntries.forEach((policyInformation) => {
            policyInformation.travelPolicyViolations.forEach((violation) => {
                violation.value.fareCombinations.forEach((fareCombination) => {
                    const faresToMatch = [fareCombination.outboundFareKey];
                    if (fareCombination.inboundFareKey) {
                        faresToMatch.push(fareCombination.inboundFareKey);
                    }

                    if (faresArray.every((fare) => faresToMatch.includes(fare))) {
                        violationKeys.push(violation.key);
                    }
                });
            });
        });

        // map them to travelPolicyDefinitions strings
        return violationKeys.map((key) => results.travelPolicyDefinitions[key]);
    }
}
