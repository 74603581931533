import type { IBookingData } from "@/models/checkout/FulfilmentRequestModel";
import type { IGuestCheckoutRequest, IAuthenticatedCheckoutRequest } from "@/models/checkout/GuestCheckoutRequest";
import type { IApiClient } from "./ApiClient";

export interface IFulfilmentClient {
    guestCheckout(bookingId: string, request: IGuestCheckoutRequest): Promise<IBookingData>;
    authenticatedPayment(bookingId: string, request: IAuthenticatedCheckoutRequest): Promise<IBookingData>;
    authenticatedOnAccountPayment(bookingId: string, request: IAuthenticatedCheckoutRequest): Promise<IBookingData>;
}

const completeGuestCheckout: string = "/gql/stripe-guest-checkout/";
const authPayment: string = "/gql/stripe-user-checkout/";
const onAccountPayment: string = "/gql/on-account-user-checkout/";
const timeoutDuration = 60_000;

export class FulfilmentClient implements IFulfilmentClient {
    fulfilmentClient: IApiClient;

    constructor(retailApiClient: IApiClient) {
        this.fulfilmentClient = retailApiClient;
    }

    async guestCheckout(bookingId: string, request: IGuestCheckoutRequest): Promise<IBookingData> {
        return this.fulfilmentClient.post(`${completeGuestCheckout}${bookingId}`, request, { timeout: timeoutDuration });
    }

    async authenticatedPayment(bookingId: string, request: IAuthenticatedCheckoutRequest): Promise<IBookingData> {
        return this.fulfilmentClient.post(`${authPayment}${bookingId}`, request, { timeout: timeoutDuration });
    }

    async authenticatedOnAccountPayment(bookingId: string, request: IAuthenticatedCheckoutRequest): Promise<IBookingData> {
        return this.fulfilmentClient.post(`${onAccountPayment}${bookingId}`, request, { timeout: timeoutDuration });
    }
}
